export const lang = {
	'1month': 'כל חודש',
	'2month': 'דו חודשי',
	'2weekly': 'שבועים',
	'3weekly': '3 שבועות',
	'???': '???',
	'Journalentry': 'פקודת יומן',
	'M/P': 'M/P',
	'password_was_changed': 'הסיסמא שונתה',
	'passwords_mismatch': 'סיסמא לא תואמת',
	'Prolonged': 'Prolonged',
	'R/O': 'R/O',
	'REC': 'REC',
	'S/P': 'S/P',
	'Suppliers': 'ספקים',
	'Susp.': 'Susp.',
	'VAT_number': 'ע.מ.',
	'a_meeting': 'מתקיימת פגישה ',
	'a_meeting_note': 'הודעה על פגישה',
	'a_meeting_on': 'פגישה ב-',
  'a_task_note' : 'הודעה על משימה',
	'a_password_reminder_confirm': 'אישור הבקשה יאפשר למטופל/ת לאפס את הסיסמא.',
	'a_password_reminder_link': 'יש להיכנס ללינק המצורף על מנת לראות במי מדובר.',
	'a_password_reminder_request_from_patient': 'התקבלה בקשה לאיפוס סיסמא למטופל/ת שלך. ',
	'a_patient_of_yours_wants_to_reset_password': 'מטופל/ת שלך רוצה לעשות איפוס סיסמא',
	'a_reminder': 'תזכורת',
	'a_reminder_with_the_exceptions': 'רשימת הפגישות שלא נקלטו',
	'about': 'אודות',
	'about_the_writer': 'אודות המחבר',
	'about_us': 'אודות',
	'accept_events': 'קבל אירועים אך ללא התנגשויות',
	'account': 'חשבון',
	'account_activation_error': 'החשבון לא הופעל,אירעה שגיאה',
	'account_in_hashav': 'חשבון בחשבשבת',
	'account_in_hashav_cash': 'חשבשבת קופת מזומנים',
	'account_in_hashav_cheque': 'חשבשבת קופת שיקים',
	'account_in_hashav_credit': 'חשבשבת קופת אשראי',
	'account_in_hashav_patient_all': 'חשבשבת קופת מטופלים',
  'account_in_hashav_vat' : 'מע"מ',
  'account_in_hashav_income' : 'הכנסות',
  'account_in_hashav_expenses' : 'הוצאות',
	'account_num': 'מס\' חשבון',
	'account_type': 'סוג עוסק',
	'accountant': 'דוחות אוטומטיים לרו"ח',
	'accountant_insert': 'הוספת פרמטרים לרו"ח',
	'accountant_email': 'דוא"ל רו"ח',
	'accountant_every_copy': 'לשלוח כל מסמך',
	'accounting': 'הנהלת חשבונות',
	'accounting_explanation': '<h5><ul><li>רשימת חשבוניות<li/><li>רשימת הוצאות<li/><li>רשימת חשבונות הבנק של המטופלים<br>רשימת חשבונות הבנק של המטפל. <li></ul></h5>',
	'action': 'פעולה',
	'active': 'פעיל',
	'active_program': 'תוכנית פעילה',
	'active_your_account_now': 'הפעלת החשבון כעת!',
	'add': 'הוספה',
	'add_contactway': 'טלפון או דוא"ל',
	'add_location': 'הוספת חדר',
	'add_tarif': 'הוספת תעריף',
	'add_new': 'הוספת - ',
	'add_new_contact': 'איש קשר חדש',
	'add_new_patient': 'הוספת מטופלים חדשים',
	'add_new_user': 'הוספה',
	'add_one_more_invoiceline': 'הוספת שורה נוספת לחשבונית',
	'add_patient': 'הוספת מטופל.ת/פונה',
	'add_patient_extra': 'הוספת מטופל.ת/פונה מפורט',
	'addd': 'הוספת ',
	'added_at': 'נוסף',
	'address': 'כתובת',
	'address_line_1': 'שורת אמצעי קשר 1',
	'address_line_2': 'שורת אמצעי קשר 2',
	'admin': 'ניהול',
	'admin_action': 'פעולת מנהל',
	'adminconfigs': 'הגדרות ניהול המערכת',
	'admininvoice_details': 'פרטי חשבונית',
	'admininvoicelines': 'שורות החשבונית',
	'admininvoices': 'חשבוניות ניהול',
	'administration': 'ניהול',
	'advanced_by_percent': 'מקדמה על פי האחוז מהמחזור השנתי',
	'advanced_percent': 'אחוז מקדמות',
	'affect': 'אפקט',
	'affect_desc': 'אאוטימי, מלא, יציב, תואם לתכנים',
	'age': 'גיל',
	'all_arrived': 'נוכחות מלאה',
  'all_invoices': 'חשבוניות',
  'all_patients': 'כל המטופלים',
  'all_patients_all_fus' : 'יומן מורחב',
	'all_options': 'הכל',
	'ownerbankaccounts': 'חשבונות הבנק של המטפל',
	'all_users': 'משתמשים',
	'allday': 'כל היום',
	'alone': 'יחיד',
	'already_exists': 'קיים כבר',
	'patient_already_exists': '  מטופל.ת קיים.ת ',
	'already_registered': 'רשומ/ה?',
	'amount': 'כמות',
	'amountXfrequency': 'כמות X תדירות',
	'amount_output': 'כמות ליחידת זמן',
	'amountt': 'סכום',
	'an_error_occurd': 'אירעה שגיאה',
	'an_error_occured': 'אירעה שגיאה',
	'announce': 'עדכון',
	'announces': 'עדכונים',
	'appearance': 'הופעה',
	'appointments': 'פגישות',
	'approve': 'אישור',
	'arrived': 'הגיע/ה',
	'at_least_chars': 'תווים לפחות',
	'atache': 'מצורף',
	'atc5_code': 'ATC5 קוד',
	'atc5_name': 'ATC5 שם',
	'attach': 'שיוך',
	'attachment': 'צרופה',
	'auto_message': 'תזכורת עצמית',
	'auto_renew': 'חידוש אוטומטי',
	'auto_renew_billing': 'חיוב עבור חידוש אוטומטי',
	'autotexts': 'טקסטים מובנים',
	'autotext': 'טקסט מובנה',
	'back': 'חזרה',
	'back_home': 'בחזרה לדף הבית',
	'back_to_dates': 'בחזרה לבחירת תאריכים',
	'back_to_journal': 'בחזרה למעקב',
	'back_to_patient_details': 'בחזרה לפרטי מטופל',
	'back_to_details': 'בחזרה לפרטים',
	'back_to_medics': 'בחזרה לרשימת תרופות',
	'background': 'רקע',
	'bad-credentials': 'שם המשתמש או הסיסמא שגויים',
	'bad_credentials': 'שם המשתמש או הסיסמא שגויים',
	'bad_email': 'דוא"ל שגוי',
	'balance': 'מצב כספי',
	'bank': 'בנק',
	'bank_code': 'קוד בנק',
	'bank_id': 'בנק',
	'bank_name': 'שם',
	'bank_transfer': 'העברה',
	'bankaccount': 'חשבון בנק',
	'bankaccount_number': 'מס\' חשבון בנק',
	'bankaccounts': 'חשבונות בנק',
	'banks': 'בנקים',
	'barter': 'ברטר',
	'base_tables': 'טבלאות בסיס',
	'basic_account_info': 'פרטי חשבון משתמש',
	'before_today': 'אין לקלוט תאריך קטן מהיום',
	'belong_to_group': 'משוייך/ת לקבוצה',
	'beshaa': 'בשעה',
	'betaarich': 'בתאריך',
	'blog': 'בלוג',
	'body': 'תוכן',
	'branch': 'סניף',
	'branch_id': ' מספר סניף',
	'branch_name': 'שם',
	'branches': 'סניפים',
	'break': 'סיכום ביניים',
	'break_length': 'משך הפסקה',
	'buy': 'קניה',
	'buy_again': 'רכישה נוספת',
	'buy_selected_items': 'קניית המוצרים המסומנים',
	'by': 'ל',
	'by_attending': 'לפי הגעה',
	'by_clicking_i_agree': 'אני מסכימ/ה',
	'by_downloading_this email': '* בהורדת מסמך זה את/ה מאשר/ת קבלת מסמכים בדואר אלקטרוני. ',
	'by_group': 'לכל הקבוצה',
	'by_month': 'לפי חודש',
	'by_participant': 'לפי משתתף',
	'by_patient': 'לפי מטופל',
	'by_payor': 'לפי מממן',
	'by_type': 'לפי סוג',
	'by_year': 'לפי שנה',
	'calc_income_as_next_month': 'להראות בחישוב החוב, חשבוניות מהחודש הבא',
	'calendar': 'יומן',
	'calendar_config': 'הגדרות יומן',
	'calendar_end_time': 'שעת סיום היומן',
	'calendar_rows_interval': 'רווח השורות ביומן',
	'calendar_start_time': 'שעת תחילת היומן',
	'cancel': 'ביטול',
	'canceled': 'בוטל',
	'canceled_meeting': 'פגישה מבוטלת',
	'cant_delete_fundamental_followup_types': 'אין למחוק סוגי פגישה',
	'cant_edit_past': 'אי אפשר לערוך ארועים בעבר',
	'update_before_yesterday': 'לא ניתן לקלוט או לעדכן אירועים בעבר',
	'vacation_before_tomorrow': 'לא ניתן לקלוט חופשה היום או בדיעבד',
	'vacations_holiday_create': 'יצירה/עדכון של חופשות ביומן, המבוססות על החגים שבחרתם',
	'vacations_holiday_create_remarks': '* ניתן לשנות/למחוק כל חופשה ע"י כניסה ליומן',
	'vacations_holiday_create_remarks_extra': '* הפעלה מחדש של תהליך היצירה, ייצר חופשות חדשות וידרוס את הקיימות',
	'cant_find_user_data': 'לא מצאנו את המשתמש',
	'cash': 'מזומן',
	'certified_copy': 'העתק נאמן למקור',
	'change': 'שינוי',
	'change_existing_default': 'להחליף כותרת ברירת מחדל',
	'change_limit_date': 'שינוי תאריך סיום',
	'change_password': 'שינוי סיסמה',
	'change_plan': 'שינוי תוכנית',
	'change_program': 'שינוי תוכנית',
	'change_series': 'שינוי פגישה או סדרת פגישות',
	'characters': 'תווים',
	'charge': 'חיוב',
	'charge_mode': 'אופן חיוב',
	'charged': 'לחיוב',
	'charged_manualy': 'חשבונית חיצונית',
	'charged_manualy_payor': 'חשבונית חיצונית-מממן',
	'chat': 'צ\'אט',
	'chat_link': 'כניסה לצ\'אט',
	'chatpatients': 'מטופלים רשומים לתכתובת',
	'check_list': 'לבדיקת הרשימה והדפסה',
	'checking_the_verification_code.': 'קוד האימות שלך נבדק',
	'checkout': 'לקניה',
	'checkout_with_paypal': 'פאי פאל',
	'cheque': 'שיק',
	'cheque_deposit': 'הפקדת שיקים',
	'cheque_num': 'מס שיק',
	'cheque_report': 'הפקדות',
	'cheques': 'שיקים',
	'cheques_report': 'הפקדות',
	'choose_file': 'בחירת קובץ',
	'choose_option': 'בחירה',
	'choose_template_design': 'בחירת תבנית',
	'chosen_items': 'הנבחרים',
	'city': 'ישוב/\עיר',
	'clear': 'איפוס',
	'click_here': 'יש ללחוץ כאן',
	'click_here_to_update_card': 'יש להקליק על הלינק המצורף לעדכון פרטי התשלום',
	'click_here_to_view_message': 'יש להקליק על הלינק כדי לראות את ההודעה',
	'click_link_to_register': 'יש להקליק על הלינק הזה כדי להירשם למערכת ההודעות',
	'click_me': 'הקלק עלי',
	'clinical': 'קליני',
  'clinic_financial': 'קליני וכספי',
	'cliniq': 'קליניק',
	'cliniq_management': 'ניהול המרפאה',
	'close': 'בוצע',
	'close_btn': 'יציאה',
	'closed': 'סגור',
	'closedf': 'בוצעה',
	'show_closed': 'כל המשימות',
	'code': 'קוד',
	'code_is_not_valid': 'הקוד לא תקין',
	'codecolor': 'קוד html של צבע',
	'coin': 'ש"ח',
	'coin_symbol': '₪',
	'color': 'צבע',
	'comment': 'תגובה',
	'comment_successfully_added': 'התגובה נוספה בהצלחה',
	'comments': 'תגובות',
	'configcliniqs': 'הגדרות',
	'configcliniqs_accountant': 'פרמטרים למשלוח דוחות לרואה חשבון בדוא"ל',
	'configuration': 'הגדרות',
	'confirm': 'אישור',
	'confirm_all_events_that_not_conflict': 'אישור קליטת כל הפגישות שאינן מתנגשות עם פגישות אחרות',
	'confirm_change_default': 'אישור החלפת ברירת מחדל',
	'confirm_change_default_invoice_per_followup': 'שימו לב! הינכם עומדים לשנות את מערכת הקישור בין מעקבים לחשבוניות',
	'confirm_duplicate': 'השם קיים במערכת, האם לקלוט שם נוסף כזה?',
	'confirm_event_series': 'אישור סדרת פגישות',
	'confirm_meeting_on_holiday': 'אישור פגישה ביום שבתון',
	'confirm_password': 'אימות סיסמה',
	'confirmation': 'אישור',
	'confirmation_failed': 'אישור ההרשמה נכשל, אנא פנה אלינו בהקלקה על ? למטה ואנו נסייע!',
	'confirmation_of_your_order': 'אישור הזמנה',
	'conflict_error': 'יש התנגשות בלוח הזמנים',
	'conflict_in_the_future': 'יש התנגשות בעתיד',
	'conflict_vent_in_the_future': 'פגישה שמתנגשת עם פגישה עתידית',
	'conscious': 'מודעות',
	'consciousness': 'מודע',
	'consciousness_desc': 'צלול',
	'contac_us_form': 'טופס יצירת קשר',
	'contac_us': 'יצירת קשר',
	'contact': 'איש קשר',
	'contact_details': 'פרטים נוספים',
	'contact_me': 'צרו איתי קשר',
	'contact_name': 'שם איש קשר',
	'contact_page': 'דף איש קשר',
	'contact_us': 'צרו קשר',
	'contacts': 'אנשי קשר',
	'contacttype': 'סוג איש קשר',
	'contacttype_id': 'סוג איש קשר',
	'contacttypes': 'סוגי איש קשר',
	'contactway': 'פרטי קשר',
	'contactway_identifier': 'מזהה',
	'contactways': 'פרטי קשר',
	'contactway_1': 'טלפון דוא"ל או כתובת',
	'content': 'תוכן',
	'thinking_content': 'תוכן חשיבה',
	'continent': 'יבשת',
	'continue': 'להמשך',
	'continue_shopping': 'המשך לקנות',
	'continue_to_email': 'לשליחת הדוא"ל',
	'control_panel': 'לוח בקרה',
	'cooperation': 'שיתוף פעולה',
	'country': 'מדינה',
	'courier': 'מוביל',
	'couriers': 'מובילים',
	'create': 'יצירת',
	'create_a_translated_certified_copy': 'ליצירת העתק נאמן למקור באנגלית',
	'create_contact': 'הוספת איש קשר',
	'create_excel': 'יצוא לאקסל',
	'create_followup': 'הוספת מעקב',
	'create_patient': 'הוספת מטופל',
	'create_test': 'יצירת שאלון',
	'create_user': 'יצירות משתמש',
	'created': 'נוצר',
	'created_at': 'נקלט בתאריך',
	'created_at_render': 'נקלט בתאריך',
	'credit': 'קרדיט',
	'credit_card': 'אשראי',
	'credit_card_4': '4 ספרות אחרונות',
	'credit_card_4_digits': '4 ספרות אחרונות של כרטיס האשראי',
	'credit_card_company': 'חברת אשראי',
	'credit_card_expired': 'פג תוקפו של כרטיס האשראי שלך',
	'credit_card_expired_admin': 'פג תוקפו של כרטיס האשראי, דוא"ל נשלח למשתמש',
	'credit_card_expired_user': 'פג תוקפו של כרטיס האשראי שנתת לנו בהרשמתך. יש לעדכן את פרטי האשראי המלאים והתקפים בהקדם האפשרי',
	'credit_invoice': 'חשבונית זיכוי',
	'credit_invoice_short': 'זיכוי',
	'credit_invoice_against': 'חשבונית זיכוי כנגד ',
	'creditcard': 'כרטיס אשראי',
	'creditcards': 'כרטיסי אשראי',
	'creditcomp': 'חברת אשראי',
	'creditcomps': 'חברות אשראי',
	'credittype': 'סוג עסקת אשראי',
	'credittypes': 'סוגי עסקת אשראי',
	'curent': 'נוכחי',
	'cycle': 'המחזור העיסקי בש"ח',
	'daily': 'יומי',
	'data': 'נתונים',
	'data_saved_on': 'הנתונים נשמרו בנתיב הבא',
	'date': 'תאריך',
	'do_date': 'מועד ביצוע',
	'date_range': 'בחירה',
	'date_start_accounting': 'תאריך תחילת הנהלת חשבונות',
	'date_to': 'עד תאריך',
	'day': 'יום',
	'day_on': 'ביום',
	'day_before': 'יום לפני',
	'day_end': 'יום סיום',
	'day_start': 'יום התחלה',
	'days': 'ימים',
	'dear': 'שלום',
	'debt': ' חוב',
  'calculated_debt': 'חוב מחושב',
	'debts': 'חובות',
	'real_debt': 'חוב סופי',
  'debt_details' : 'פירוט פגישות וחיובים',
	'debt_patient': 'חוב למטופל',
	'debt_report': 'חוב חודשי',
	'debt_total': 'חוב כולל עד כה',
	'debts_report': 'דוח חוב',
  'debtUntilDate': 'חוב ל',
  'debtUntilNow': 'חוב עד היום',
	'default': 'ברירת מחדל',
	'default_heading': 'כותרת מכתבים',
	'default_font_size': 'ברירת מחדל לגודל פונט(בשדות הטקסטואליים)',
	'font_size': 'גודל פונט',
	'default_price': 'מחיר',
	'default_signature': 'חתימה',
	'defaultpatienttype': 'ברירת מחדל לסוג פגישה',
	'delete': 'מחיקה',
	'delete-': 'מחיקת ',
	'delete_failed': 'המחיקה נכשלה',
	'delete_successful': 'המחיקה בוצעה',
	'deleted': 'נמחק',
	'is_deleted': 'נמחק',
	'deleted_remarks': 'הערות מחיקה',
	'deleted_at': 'נמחק ב-',
	'delusions': 'מחשבות שווא',
	'delusions_desc': 'לא נמצאו',
	'demo_user': 'גירסת הדגמה, נתוני הדמו משותפים לכל המשתמשים.',
	'departure': 'נקודת התחלה',
	'deposit_to': 'הפקדה ל-',
	'deposits': 'הפקדות',
	'description': 'תיאור',
  'description_and_instractions' : 'תיאור והנחיות למילוי',
  'thank_you_for_filling' : 'השאלון/טופס התקבל אצל המטפל, תודה',
  'test_by' : 'שאלון/טופס מאת ',
	'destination': 'יעד',
	'details': 'פרטים',
	'diagnosis': 'אבחנות',
	'diagp': 'אבחנה',
	'diagp_name': 'אבחנה',
	'diagpine': 'אבחנה',
	'diagppatient': 'אבחנה',
	'diagppatients': 'אבחנות',
	'diagps': 'אבחנות',
	'discount': 'הנחה',
	'divorced': 'גרוש/ה',
	'do_nothing': 'על תעשה דבר',
	'do_you_confirm': 'אישור',
	'do_you_confirm_delete': 'האם למחוק?',
  'do_you_confirm_change': 'האם לשנות?',
	'doc_type': 'סוג מסמך',
	'doc_type_csv': 'קובץ CSV',
	'doc_type_hash_hesh': 'לקוחות',
	'doc_type_hash_mov': 'תנועות-חשבשבת ',
	'doc_type_hash_xlsx': 'תנועות חשבשבת אקסל',
	'doc_type_pdf': 'קובץ PDF',
	'fin_doctype': 'מסמך חשבונאי',
	'doctype': 'מסמך',
	'finResource_list': 'מסמכים חשבונאיים',
	'doctor_diary': 'יומן',
	'document': 'מסמך',
	'document_code': 'מספר מסמך',
	'document_copy': 'העתק מסמך',
	'document_preview': 'הצגת מסמך',
	'document_template': 'תבנית למכתב',
	'document_templates': 'תבניות למכתב',
	'document_type': 'סוג מסמך',
	'documentation': 'תיעוד',
	'documents': 'מסמכים',
	'documenttemplates': 'תבניות למכתבים',
	'documenttypes': 'סוגי מסמך',
	'does_not_take_time': 'לא תופס זמן מוגדר',
	'done': 'בוצע',
	'done_and_end': 'בוצע וסיום',
	'dosage': 'מינון',
	'dosage_form': 'מינון',
	'download': 'הורדה',
  'move_to_download' : 'מעבר להורדת הקבצים',
	'download_app': 'אפליקציה',
	'download_pdf_after_save': 'הורדת אחרי pdf שמירה',
	'save_process': 'המסמך נשמר',
	'download_process': 'הורדת המסמך מתבצעת',
	'download_success': 'השמירה וההורדה הסתיימה בהצלחה',

	'due_to_regulation': 'בשל מגבלות רגולציה אי אפשר לשלוח מוצר זה כמוצר רגיל',
	'duplicate': 'שכפול',
	'duplicate-': 'שכפול ',
  'duration': 'משך',
	'dx_status': 'Dx Status',
	'early_adopter': 'האם את/ה מגדיר/ה את עצמך Early adopter?',
	'edit': 'עריכה',
	'edit_admininvoice': 'עריכה',
	'edit_admininvoiceline': 'עריכה',
	'edit_autotext': 'עריכת טקסט מובנה',
	'edit_bank': 'עריכת בנק',
	'edit_bankaccount': 'עריכת חשבון בנק',
	'edit_branch': 'עריכת סניף',
	'edit_configcliniq': 'עריכת הגדרות',
	'edit_configcliniq_first': 'עריכת הגדרות - לשימוש בעת הכניסה הראשונה למערכת',
	'edit_contact': 'עריכת איש קשר',
	'edit_contacttype': 'עריכת סוג איש קשר',
	'edit_contactway': 'עריכת אמצעי קשר',
	'edit_courier': 'עדכון פרטי מוביל',
	'edit_creditcard': 'עריכת כרטיס אשראי',
	'edit_creditcomp': 'עריכת חברת אשראי',
	'edit_credittype': 'עריכת סוג עסקת אשראי',
	'edit_diagp': 'עריכת אבחנה',
	'edit_document': 'עריכת מסמך',
	'edit_documenttype': 'עריכת סוג מסמך',
	'edit_email': 'עריכת דוא"ל',
	'edit_expense': 'עריכת הוצאה',
	'edit_expensegroup': 'עריכת קבוצת בוצאה',
	'edit_expensetype': 'עריכת סוג הוצאה',
	'edit_followup': 'עריכת מעקב',
	'edit_followuptype': 'עריכת סוג מעקב',
	'edit_header_and_settings': 'עריכת כותרת והגדרות',
	'edit_headture': 'עריכת כותרת וחתימה',
	'edit_invoice_num': 'הכנסת מספר חשבונית/קבלה ראשונה',
	'edit_num': 'הכנסת מספר ראשון בסדרה',
	'edit_labtest': 'עריכת בדיקה',
	'edit_medic': 'עריכת תרופה',
	'edit_meetingtype': 'עריכת סוג פגישה',
	'add_meetingtype': 'הוספת סוג פגישה',
	'add_expensetype': 'הוספת סוג הוצאה',
	'edit_memo': 'עריכת תזכורת',
	'edit_nondrug': 'עריכת טיפול ',
	'edit_notification': 'עריכת הודעה',
	'edit_patient': 'עריכת מטופל',
	'edit_patienttype': 'עריכת סוג מטופל',
	'edit_phone': 'עריכת טלפון',
	'edit_profile': 'עריכת פרופיל',
	'edit_proforma': 'עריכת חשבונית',
	'edit_proforma_num': 'הכנסת מספר חשבון ראשון בסדרה.',
	'edit_justinvoice_num': 'הכנסת מספר חשבונית מס בסדרה.',
	'edit_receipt_num': 'הכנסת מספר קבלה בסדרה.',
	'edit_proformaline': 'עריכת שורה',
	'edit_program': 'עריכה',
	'edit_purchase': 'עריכת רכישה',
	'edit_status': 'עריכת סטטוס',
	'edit_supplier': 'עריכת ספק',
	'edit_tax': 'עריכת מס',
	'edit_test': 'עריכת שאלון',
	'edit_user': 'עריכת משתמש',
	'editor': 'עורך',
	'email': 'דוא"ל',
	'email_not_must': 'דוא"ל (לא חובה)',
	'email_accountant': 'לשלוח מסמכים חודשיים לרו"ח',
	'email_choose_or_insert_new': 'בחירת דוא"ל או הכנסת חדש',
	'email_copy': 'שליחה בדוא"ל',
	'email_phone': 'דוא"ל או טלפון',
	'email_not_found': 'דוא"ל לא קיים במערכת',
	'email_support': 'https://mail.google.com/mail/?view=cm&fs=1&tf=1&to="mycliniq.manage@gmail.com" target="_blank"',
	'email_support_mobile': 'https://mail.google.com/mail/?view=cm&fs=1&tf=1&to="mycliniq.manage@gmail.com" target="_blank"',
	'email_suspended': 'השעיה משימוש ב-My-Cliniq עקב אי תשלום',
	'email_taken': 'דוא"ל כבר קיים במערכת',
	'email_tracking': 'מעקב דוא"לים',
	'emails': 'נשלח בדוא"ל',
	'emails_phones': 'דוא"לים וטלפונים',
	'empty': 'ריקון',
	'en': 'אנגלית',
	'end': 'סיום',
	'end_active_chat': 'סיום שיחה פעילה',
	'end_date': 'תאריך סיום',
	'end_change_date': 'תאריך סיום/שינוי',
	'end_date_must_be_bigger_than': 'תאריך סיום חייב להיות גדול מתאריך התחלה',
	'ended': 'הסתיים',
	'ended_successfully': 'ביצוע ממשק פתוח הסתיים בהצלחה',
	'ends_at': 'עד',
	'eng': 'אנגלית',
	'enter_your_new_password': 'יש למלא סיסמא חדשה',
	'error': 'שגיאה',
	'error_creating_your_account.': 'שגיאה ביצירת החשבון',
	'error_saving_template': 'שגיאה בשמירת המסמך',
	'errors_report': 'דיווח על שגיאות ובאגים או בקשה לפיצ\'רים חדשים',
	'event_end': 'אירוע נגמר',
	'every': 'כל',
	'exactly': 'בדיוק',
	'existing': 'קיימים',
	'exp_modal_choose_date': 'בחרו תאריך',
	'exp_modal_choose_report_by_date': 'הפקת דוח לפי לקוח',
	'exp_modal_title': 'הפקת דוח הכנסות להנהלת חשבונות',
	'expand': 'הרחבה',
	'expense': 'הוצאה',
	'expense_description': 'תאור הוצאה',
	'expense_type': 'סוג הוצאה',
  'expensetype_name': 'סוג הוצאה',
	'expensegroups': 'קבוצות הוצאה',
	'expenseitems': 'סוגי הוצאה',
	'expenses': 'הוצאות',
	'expenses_by_month': 'דו\'ח הוצאות',
	'expenses_by_type': 'סוג הוצאה',
	'expenses_report': 'דוח הוצאות',
	'expensetype': 'סוג הוצאה',
	'expensetypes': 'סוגי הוצאה',
	'expertise': 'התמחות',
	'expired_password': 'פג תוקפה של הסיסמא שלך',
	'export': 'יצוא',
	'export_to_doc': 'יצוא ל-',
	'export_to_heshin': 'יצוא חשבונות לחשבשבת -',
	'export_to_movein': 'יצוא תנועות לחשבשבת -',
	'eye_contact': 'קשר עין',
	'facebook': 'פייסבוק',
	'facebook_url': 'https://www.facebook.com/groups/656857967728173/',
	'family_name': 'שם משפחה',
	'family_status': 'מצב משפחתי',
	'fax': 'פקס',
	'female': 'נקבה',
	'field_id': 'זיהוי שדה',
	'field_must_be_unique': 'הערך צריך להיות ייחודי',
	'field_name': 'שם שדה',
	'file_content': 'תוכן המסמך',
	'file_format': 'סוג קובץ',
	'file_name': 'שם הקובץ בהורדה',
	'file_type': 'סוג קובץ',
	'files_to_import1': '* יש לטעון רק קבצי "csv" או "xlsx".',
	'files_to_import2': 'קובץ csv צריך להיות ב-"endocing - utf8".',
	'files_to_import3': 'במידה ויש שורת כותרת ניתן למחוק אותה לפני היבוא.',
	'financial': 'כספי',
	'finish': 'סיום',
	'finished': 'סיימתי',
	'finresource_num': 'מס מסמך',
	'first_invoice_num_error': 'מספר חשבונית.קבלה ראשונה צריך להיות גדול מ-0',
	'first_invoice_num': 'כדי להתחיל להשתמש במערכת החשבוניות, יש להכניס מספר חשבונית/קבלה ראשונה בסדרה.',
	'first_credit_invoice_num': 'כדי להפיק חשבונית זיכוי ראשונה, יש להכניס מספר חשבונית/קבלה  זיכוי ראשונה בסדרה.',
	'first_credit_justinvoice_num': 'כדי להפיק חשבונית זיכוי ראשונה, יש להכניס מספר חשבונית  זיכוי ראשונה בסדרה.',
	'first_credit_receipt_num': 'כדי להפיק קבלה זיכוי ראשונה, יש להכניס מספר קבלה זיכוי ראשונה בסדרה.',
	'first_name': 'שם פרטי',
	'first_proforma_num': 'כדי להתחיל להשתמש במערכת חשבונות העסקה, יש להכניס מספר חשבון ראשון בסדרה.',
	'first_justinvoice_num': 'כדי להתחיל להשתמש במערכת החשבוניות, יש להכניס מספר חשבונית מס ראשון בסדרה.',
	'first_receipt_num': 'כדי להתחיל להשתמש במערכת הקבלות, יש להכניס מספר קבלה ראשון בסדרה.',
	'first_time': 'כדי להתחיל לעבוד עם המערכת עליך להכניס ערכים לשדות המסומנים במסגרת אדומה, שדות אלה הכרחיים להפעלה תקינה. ',
	'first_use': 'שימוש ראשוני',
	'followup': 'מעקב',
	'followupserie': 'תכנון יומן',
	'followup_character': 'אופי הפגישה',
	'followups': 'מעקבים',
	'followups_by_date': 'מעקבים בטווח תאריכים',
	'followups_by_month': 'מעקבים - חודשי',
	'followups_by_year': 'מעקבים - שנתי',
	'followups_descriptions': 'מעקבים עם תאורי פגישות',
	'followups_explanation': '<h5><ul><li>רשימת הפגישות.<li/><li>צפיה ביומן.<li/><li>קליטת פגישה חדשה.<br/>עדכון היומן.</li></ul></h5>',
	'followups_memorandum': 'מעקבים עם תרשומת אישית',
	'followups_report': 'חיובים',
	'followuptype': 'סוג מעקב',
  'followuptype_name' : 'סוג פגישה',
	'followuptype_id': 'סוג מעקב',
	'followuptypes': 'סוגי מעקב',
	'footer': 'חתימה',
	'footer_text': 'טקסט חתימה',
	'for': 'עבור',
	'for_internal_use_only': 'לשימוש פנימי בלבד',
	'for_payment': 'הסכום לתשלום בשקלים חדשים',
	'foreign': 'זר',
	'foreign_bank': 'שם בנק זר',
	'forever': 'לתמיד',
	'forgot_password': '» שכחת סיסמה',
	'forgot_your_password?': '» שכחת סיסמה?',
	'form': 'טופס',
	'new_form': 'טופס',
	'formula': 'נוסח',
	'free_form': 'שם לא מרשימה',
	'frequency': 'תדירות',
	'frequency_unit': 'יחידת זמן',
	'friday': 'שישי',
	'from': 'מאת',
	'from_date': 'מתאריך',
	'date_from': 'מתאריך',
	'from_patient': 'ממטופל/ת',
	'from_user': 'ממטפל/ת',
	'full_configuration': 'הגדרות מלאות',
	'gender': 'מגדר',
	'general': 'כללי',
	'generalDescription': 'תאור כללי',
	'generalDescription_desc': 'מראה תואם לגיל, הופעה מסודרת, יצירת קשר עין תואמת, תנועתיות תקינה, שתוף פעולה מלא',
	'generate_header': 'יצירת כותרת מנתוני המשתמש שלך',
	'getting_started': 'צעדים ראשונים',
	'go_back': 'חזרה אחורה',
	'go_to': 'מעבר אל',
	'go_to_software': 'להתחלת עבודה',
	'greeting': 'בברכה',
	'group': 'קבוצה',
	'group_attendancy': 'נוכחות בקבוצה',
	'group_details': 'פרטי קבוצה',
	'group_short': 'קבוצה',
	'group_type': 'סוג קבוצה',
	'groupapatient': 'שייך/ת לטיפול',
	'groupattend': 'משתתף/ת בפגישה',
	'groupattends': 'משתתפים בפגישה',
	'grouppatient': 'משתתף בקבוצה',
	'grouppatients': 'משתתפים בקבוצה',
	'groups': 'טיפולים קבוצתיים',
	'groups_long': 'טיפולים רבי משתתפים-קבוצות, משפחות, זוגי',
	'hallucinations': 'הזיות',
	'hallucinations_desc': 'נשללו במפורש, ללא התנהגות המתאימה להזיות',
	'hanetunim_hufku': 'הנתונים הופקו באמצעות תוכנת',
	'has expired': 'פג תוקפו.',
	'has_no_email': '- אין דוא"ל, החשבונית תרד למחשב',
	'has_received_a_request_to_reset_the_password_for_your_account._if_you_did_not_request_to_reset_your_password,_please_ignore_this_email': 'קיבלנו בקשה לאיפוס סיסמה. במידה ולא ביקשת זאת אנא התעלם מדוא"ל זה.',
	'hashav': 'פרמטרים לחשבשבת',
	'hashav_params': 'פרמטרים לחשבשבת',
	'hayavot': 'עסקאות חייבות (ללא מע"מ)',
	'header': 'כותרת',
	'header_default': 'ברירת מחדל למסמך',
	'header_text': 'טקסט כותרת',
	'headings_signatures': 'כותרות וחתימות',
	'headtures': 'כותרות וחתימות',
	'health_basket': 'סל בריאות',
	'heb': 'עברית',
	'hello': 'שלום',
	'hi_i_am_here': 'הי אני כאן',
	'hidden': 'הסתרה',
	'hide': 'הסתרה',
	'historical': 'היסטורי',
	'history_diagps': 'אבחנות היסטוריות',
	'history_medics': 'תרופות היסטוריות',
	'history_nondrugs': 'טיפולים היסטוריים',
	'holiday': 'חג',
	'holiday_evening': 'ערב חג',
	'holidays': 'חגים',
	'holidays-old': 'חגים',
	'home': 'בית',
	'home_page': 'דף הבית',
	'house': 'בית',
	'how_can_we_help': 'איך אפשר לסייע? ',
	'how_it_work': 'איך זה עובד',
	'how_it_works_text': '    התוכנה כוללת מענה לנושאים הבאים :<br>    ניהול יומן פגישות.<br>    ניהול התיק הרפואי (הרשומות) בהשקעת זמן ומאמץ מיזעריים (מספר דקות ביום)<br>    יומן רופא ממולא אוטומטית.<br>    כרטסת מטופלים.<br>    ספר טלפונים ומיילים.<br>    ניהול וכתיבת מסמכים.<br>    ניהול תזכורות.<br>    ניהול חיובים והכנסות נוח לכל מטופל.<br>    הוצאת חשבוניות ממוחשבות (בהתאם לדרישות רשויות המס).<br>    הפקדה קלה של שיקים.<br>    ניהול כספי מלא, כולל חישוב הכנסות לרשויות.<br>    בנוסף :<br>    כל המידע נשמר בצורה מוצפנת, הנגישה רק למטפל עצמו.<br>    שירות גיבוי אוטומטי.<br>    גישה מכל מקום דרך מחשב, טאבלט או טלפון נייד.   <br>',
	'hp': 'ח.פ.',
	'hp_long': ' - חברה פרטית',
	'hsb': 'חשבשבת',
	'html_autotext': 'טקסט מובנה',
	'html_content': 'טקסט',
	'i': 'חשבונית',
	'i_understand': 'מובן לי',
	'id': 'מספר',
	'id_number_short': 'ת.ז.',
	'id_number': 'מס ת.ז.',
	'add_id_number': 'ת.ז.',
	'identifier': 'טלפון או דוא"ל?',
	// 'id_number': 'מספר מזהה',
	'image': 'תמונה',
	'images': 'תמונה',
	'add_image_to_document': 'תמונות מצורפות',
	'imp_modal_title': 'בחר קובץ CSV',
	'imp_submit_value': 'שליחה',
	'import': 'יבוא',
	'import_followups': 'יבוא פגישות',
	'import_patients': 'יבוא מטופלים',
	'import_tags': 'יבוא תגיות',
	'in': 'ב',
  'in_' : 'ב-',
	'in_common_to_all_the_languages': 'משותף לכל השפות',
	'in_stock': 'זמין מיידית, הזמן עכשיו!',
	'in_youtube': 'My-Cliniq ביוטיוב',
	'inclusive': 'כולל',
	'income':  'דוח הכנסות',
	'income1': 'הכנסות',
	'invoices_sub_user_report': 'הכנסות לפי מטפלים',
	'income_by_payment_method': 'הכנסות לפי אמצעי תשלום',
	'income_report_by_payment_method': 'הכנסות לפי אמצעי תשלום',
	'income_report': 'דוח הכנסות',
	'admin_income_report': 'דוח הכנסות מערכת My-cliniq',
	'income_tax': 'מקדמות מס הכנסה',
	'info': 'אינפו',
	'info_tables': 'טבלאות מידע',
	'info-tables': 'טבלאות מידע',
	'insert_autotext': 'הכנסת טקסט חדש',
	'insert_calendar_item_or_invoice': 'הוספה ליומן',
	'insert_email': 'הוספת דוא"ל',
	'insert_expenses': 'קליטת הוצאות',
	'insert_headture': 'הכנסת כותרת וחתימה',
	'insert_phone': 'הוספת טלפון',
	'insert_status_form': 'הכנסת סטטוס',
	'insert_text': 'הכנסת טקסט',
	'insert_with_no_breaks': 'לקלוט את הפגישה ללא הפסקות',
	'insight': 'תובנה',
	'insight_desc': 'קיימת',
	'intake': 'אינטייק',
	'intelligence': 'אינטליגנציה',
	'intelligence_desc': 'שמורה',
	'intro': 'פתיח',
	'introduction': 'הצגת המערכת',
	'invalid_email': 'דוא"ל לא תקין',
	'inviation_to_chat': 'הזמנה לצ\'אט',
	'invoice': 'חשבונית מס/קבלה',
	'justinvoice': 'חשבונית מס',
	'against_justinvoice': 'כנגד חשבונית מס',
  'against_proforma': 'כנגד חשבון עסקה',
  'against_proformas': 'כנגד חשבונות עסקה',
  'against_receipt': 'כנגד קבלה',
	'receipt': 'קבלה',
	'admininvoice': 'חשבונית מס/קבלה',
	'invoice_and_receipt': 'חשבונית מס / קבלה',
	'invoice_by_mail': 'קבלת חשבונית בדוא"ל',
	'automatic_proforma': 'קבלת חשבון חודשי בדוא"ל',
	'invoice_copy': 'העתק חשבונית',
	'invoice_details': 'פרטי חשבונית',
	'invoice_enclosed': 'החשבונית מצורפת.',
	'invoice_header': 'כותרת חשבונית',
	'invoice_id': 'זיהוי חשבונית',
	'external_invoice_id': 'מס. חשבונית חיצונית',
	'invoice_num': 'מס\' חשבונית/קבלה ראשונה',
	'invoice_preview': 'הצגת חשבונית/קבלה לפני הדפסה ושמירה',
	'invoice_short': 'חשבונית ועוד',
	'justinvoice_preview': 'הצגת חשבונית מס לפני הדפסה ושמירה',
	'justinvoice_short': 'חשבונית מס',
	'receipt_preview': 'הצגת קבלה לפני הדפסה ושמירה',
	'receipt_short': 'קבלה',
	'invoice_template': 'תבנית לחשבון עסקה/חשבונית',
	'invoicelines': 'שורות החשבונית',
	'invoices': 'הכנסות',
	'invoices_&_expenses': 'הכנסות והוצאות',
	'invoices_by_date': 'חשבונות עסקה לפי תאריכים',
	'invoices_by_month': 'חשבונות עסקה - חודשי',
	'invoices_by_year': 'חשבונות עסקה - שנתי',
	'irs': 'קובץ אחיד',
	'irs_journal_report': 'יומן רופא לרשות המסים',
	'is_all_day': 'יום מלא',
	'is_decimal': 'מספר בלבד',
	'is_default': 'ברירת מחדל',
	'is_empty': 'יש למלא *fieldname*',
	'is_foreign': 'בנק זר',
	'is_greater': 'גדול מ-',
	'is_holiday_evening': 'ערב חג',
	'is_not_checked': 'יש לסמן את הרצוי',
	'is_not_password': 'הסיסמה צריכה להיות מורכבת מספרות ואותיות לועזיות - לפחות אות אחת גדולה ואות אחת קטנה - ובאורך של 8 תווים לפחות',
	'is_not_user_name': 'שם המשתמש צריך להיות מורכב מאותיות ומספרים, ללא תווים מיוחדים',
	'is_number': 'יש למלא מספר',
	'is_past_addition_title': 'פגישה זו נוספת בדיעבד, יש לזכור כי תאריך ההוספה מתועד',
	'is_smaller': 'קטן מ',
	// 'is_valid_value': 'יש לבחור ערך קיים/לקלוט חדש',
	'is_valid_value': 'יש לבחור *fieldname*',
	'is_valid_value_patient_contact': 'להוספת מטופל/איש קשר חדש, יש ללחוץ על הפלוס משמאל',
	'is_zero': 'יש למלא ערך',
	'it_usually_takes_about_30_seconds': 'זה לוקח כ-30 שניות',
	'item': 'מטופלים ועוד',
	'item_name': 'שם',
	'items': 'סוגים',
	'items_patient_contactway': 'שורות בטבלת אנשי קשר',
	'items_patient_document': 'שורות בטבלת מסמכים',
	'items_patient_email': 'number of items in patient emails list',
	'items_patient_phone': 'number of items in patient phones list',
	'iterations': 'מרשם חוזר',
	'iw': 'עברית',
	'joining': 'הרשמה',
	'journal': 'מעקב יומי',
	'follow_ups_report': 'דוח פגישות',
	'follow_ups_report1': 'פגישות',
	'journalentries': 'פקודות יומן',
	'judgement': 'שיפוט',
	'judgement_desc': 'תקין',
	'just_this_followup': 'רק פגישה זו',
	'kind_regards': 'בברכה',
  'is' : 'הוא',
	'l': 'מכתב',
	'labtest': 'בדיקה',
	'labtest_id': 'בדיקה',
	'labtest_name': 'שם',
	'labtestpatient': 'בדיקה',
	'labtestpatients': 'בדיקות והדמיות',
	'labtests': 'בדיקות והדמיות',
	'language': 'שפה',
  'first' : 'ראשון',
  'last' : 'אחרון',
	'last_followup': 'הפגישה האחרונה',
	'last_login': 'כניסה אחרונה',
	'last_meeting': 'פגישה אחרונה',
	'last_updated': 'עודכן לאחרונה',
	'last_update': 'עדכון אחרון',
	'last_year': 'שנה קודמת',
	'lead': 'ליד',
	'lead_message': 'הודעה',
	'lead_message_confirmation': 'אישור על קבלת מסר',
	'leadsite': 'אתר מפנה',
	'leadsites': 'אתרים מפנים',
	'left': 'נותרו',
	'length': 'משך',
	'length_password': 'סיסמא צריכה להיות באורך',
	'length_user_name': 'שם המשתמש צריך להיות באורך',
	'letter': 'מכתב',
	'medical_letter': 'מכתב סיכום',
	'letter_export': 'יצוא למכתב',
	'letters': 'מכתבים',
	'level': 'רמה',
	'license_number': 'מספר רישוי',
	'list': 'רשימה',
	'list_autotexts': 'טבלת טקסטים',
	'listen': 'האזנה',
	'live_note': 'הוספת פתק',
	'loading': 'הפרטים נטענים',
	'local': 'מקומי',
	'login': 'כניסה',
	'logo': 'לוגו',
	'logout': 'יציאה',
	'logs': 'קבצי לוג',
	'long': 'ארוכה',
	'long_live': 'תחי המהפכה,',
	'made_by_admin': 'נוצר על ידי מנהל',
	'made_by_user': 'נוצר על ידי משתמש',
	'main': 'ראשי',
	'male': 'זכר',
	'marital_status': 'מצב משפחתי',
	'parents_marital_status': 'מצב משפחתי-הורים',
	'married': 'נשוי/אה',
	'mass_edit': 'עריכה של פרטים רבים',
	'max_size_is_6MB': 'הגודל המקסימלי המותר הוא 6MB',
	'medic': 'תרופה',
	'medic-name': 'שם התרופה',
	'medic_amount': 'כמות',
	'medic_name': 'תרופה',
	'medical_conf': 'סודי רפואי',
	'default_medical_conf': 'סודי רפואי כברירת מחדל',
	'medical_data': 'רפואי',
	'medical_file': 'תיק רפואי',
	'medical_record': 'רשומה רפואית',
	'medical_record_title': 'רשומה רפואית',
	'medication_name': 'שם התרופה',
	'medications': 'תרופות',
	'medicine': 'תרופה',
	'medicpatient': 'תרופה',
	'medicpatients': 'תרופות',
	'medicppatients': 'תרופות',
	'medics': 'תרופות',
	'meeting': 'פגישה',
	'meeting_configuration': 'הגדרת פגישה',
	'meeting_day': 'ביום הפגישה',
	'meeting_description': 'תיאור פגישה',
	'meeting_ends_at': 'הפגישה הקודמת מסתיימת ב: ',
	'meeting_length': 'ברירת מחדל למשך פגישה',
	'meeting_of_the_day': 'הפגישות של היום',
	'meeting_on_holiday': ' - פגישה בזמן חופשה',
	'meeting_on_holiday_add_edit': 'שינוי או הוספת פגישה בזמן חופשה הוא חד פעמי',
	'meeting_reminder_vacation_message': 'פגישה/תזכורת/חופשה/הודעה',
	'meeting_text': 'טקסט ברירת מחדל',
	'meeting_time': 'זמן פגישה',
	'meeting_title': 'תיאור פגישה',
	'vacation_title': 'תיאור חופשה',
	'meetingtype': 'סוג פגישה',
	'meetingtype_name': 'סוג פגישה',
	'meetingtype_price': 'מחיר פגישה',
	'meetingtype_id': 'סוג פגישה',
	'meetingtypes': 'סוגי פגישה',
	'membership_auto_renew': 'חידוש הרשמה אוטומטי',
	'memo': 'תזכורת',
	'memory': 'זכרון',
	'memory_desc': 'תקין לכל טווחיו',
  'reality_check': 'בוחן מציאות',
	'reality_check_desc': 'תקין',
	'memos': 'תזכורות',
	'menu': 'תסוג',
	'message': 'הודעה',
	'min': 'דק',
	'minute': 'שעה',
	'minutes': 'דקות',
	'misc': 'שונות',
	'model_name': 'סוג מסמך',
	'monday': 'שני',
	'month': 'חודש',
	'month1': 'כל חודש',
	'month2': 'דו חודשי',
	'month_before': 'חודש קודם',
	'monthly': 'חודשי',
	'monthly_expenses': 'דוח הוצאות',
	'more': 'מידע נוסף',
	'more_details': 'פרטים נוספים',
	'more_details_inside': 'פרטים נוספים בפנים',
	'more_information': 'מידע נוסף',
	'move_to_the_next_tab': 'מעבר אל הטאב הבא - ',
	'movement': 'תנועה',
	'must_be_positive_number': 'יש למלא מספר גדול מ-0',
	'must_be_not_negative': 'יש למלא מספר לא שלילי',
	'my_account': 'החשבון שלי',
	'my_page': 'העמוד שלי',
	'my_settings': 'הגדרות שלי',
	'n': 'לא',
	'name': 'שם',
	'display_name': 'שם',
	'name_en': 'שם באנגלית',
	'name_of_business': 'שם בית העסק',
	'name_or_email': 'שם משתמש או דוא"ל',
	'login_string': 'שם משתמש או דוא"ל',
	'need_help': 'צריכים עזרה? ',
	'network_error': 'יש כרגע תקלה בחיבור לרשת. נא לבדוק שהחיבור תקין ובמידת הצורך לרענן את המסך ולהיכנס שנית',
	'network_error1': 'בשעה זו, המערכת עוברת שדרוג, במידה ותרצו עדכון על חזרתה כיתבו לנו ונעדכן אתכם. אנו משתדלים לעשות את זה מהר ככל האפשר. ',
	'never': 'אף פעם',
	'new': 'חדש',
	'new_billing_api_message': 'חיוב חדש עבור חידוש אוטומטי',
	'new_image': 'תמונה חדשה',
	'new_item': 'מוצר חדש',
	'new_nondrug': 'טיפול חדש',
	'new_password': 'סיסמא חדשה',
	'new_user_register': '» עדיין לא נרשמת?',
	'existing_user_login': '» כבר נרשמת?',
	'new_value_must_be_greater_than_old_value': 'המספר החדש חייב להיות גדול ממספר החשבונית האחרונה',
	'next': 'הבא',
	'next_followup': 'הפגישה הבאה',
	'next_meeting': 'פגישה הבאה',
	'next_meeting_at': 'הפגישה הבאה',
	'next_meeting_strats_at': 'הפגישה הבאה מתחילה ב:',
	'next_pay': 'התשלום הבא',
	'no': 'לא',
	'no change existing_default': 'לא להחליף ',
	'no_breaks': 'ללא',
	'no_cancellation': 'ללא ביטול',
	'no_change_in_followups': 'לא ניתן לערוך שינויים לאחר שלושה ימים ממועד הפגישה',
	'no_charge': 'ללא חיוב',
	'no_lines': 'אין שורות לחשבונית',
	'no_medical_conf': 'ללא',
	'no_partner': 'עם מי נפגשים?',
	'no_program': 'ללא תוכנית',
	'no_repeat': 'ללא חזרה',
	'no_result': 'אין תוצאה!',
	'no_result_to_insert_new_click_plus': 'אין תוצאה!',
	'no_rows_yet': 'אין עדיין נתונים',
	'no_template_design': 'ללא תבנית',
	'no_user': 'אין לך אישור',
	'no_valid_data_found': 'לא נמצאו נתונים מתאימים',
	'non_conflict': 'לא מתנגש',
	'nondrug': 'טיפול ',
	'nondrug_free_form': 'שם לא מרשימה',
	'nondrug_name': 'שם',
	'nondrugpatient': 'טיפול',
	'nondrugpatients': 'טיפולים',
	'nondrugs': 'טיפולים',
	'none': 'לא',
	'none_active': 'לא פעיל',
	'nonee': 'ללא',
	'normal_range': 'טווח נורמאלי',
	'not': 'לא',
	'not_active': 'לא פעיל',
	'pending': 'בהמתנה',
	'not_charged': 'לא מחויב',
	'not_identicalToPassword': 'סיסמאות לא זהות',
	'not_invoice_by_mail': 'לא',
	'not_paid_yet': 'עדיין לא שולם',
	'not_payd': 'לא שולם',
	'not_permanent': 'לא קבוע',
	'not_registered_yet': 'עדיין לא נרשמת?',
	'not_takes': 'לא תופס',
	'not_takes_place': 'לא תופס מקום',
	'note': 'פתק',
	'notes': 'הערות לפגישה',
	'vacation_notes': 'הערות לחופשה',
	'notification': 'הודעה',
	'notification_default': 'ברירת מחדל להודעות',
	'notification_time': 'זמן להודעה',
	'notifications': 'הודעות',
	'notifiy': 'קבלת הודעות',
	'now_on': 'כל הפגישות מעכשיו',
	'now_until_date': 'פגישות מעכשיו עד לתאריך(כולל)',
	'number': 'מספר',
	'number_of_items_in_patient_email': 'מספר הסוגים ברשימת הדוא"לים למטופל',
	'number_of_items_in_patient_phone': 'מספר הסוגים ברשימת הטלפונים למטופל',
	'number_of_kids': 'מספר ילדים',
	'number_to_create': 'מספר לייצר',
	'ohoops_error': 'אופס... נראה שיש לנו תקלה באתר, תקלה זו דווחה לצוות הטכני',
	'mr': 'מ.ר.',
	'om': 'ע.מ.',
	'om_long': ' - ע.מ.',
	'on': 'ביום ',
	'ngo': 'ע.ר.',
  'ngo_long': 'עמותה רשומה',
	'once': ' חד פעמי',
	'one_of_them_should_be_filled': 'שני השמות ריקים',
	'one_time': 'חד פעמי',
	'one_time_appointment': 'פגישה חד פעמית',
	'one_time_appointment_-_no_series': 'פגישה חד פעמית - לא סידרה',
	'only': 'רק',
	'only_one': 'רק פגישה זו',
	'only_one_of_them_should_be_filled': 'יש למלא רק שם אחד',
	'op': 'ע.פ.',
	'op_long': ' - עוסק פטור',
	'open': 'הופנתה',
	'opt_csv': 'csv',
	'csv': 'csv',
	'opt_dat': 'dat',
	'opt_other': 'other',
	'opt_pdf': 'pdf',
	'opt_xlsx': 'xlsx',
	'xlsx': 'xlsx',
	'or': 'או',
	'or_any_other_generic': '* או כל תכשיר גנרי מתאים ',
	'order': 'הזמנה',
	'order_of_appearance': 'סדר הופעה',
	'order_num': 'סדר הופעה',
	'orderedList': 'רשימה ממוספרת',
	'organization': 'ארגון',
	'organized': 'האם חשוב לך שניהול הקליניקה יהיה מסודר?',
	'orientation': 'התמצאות',
	'orientation_desc': 'תקינה במקום בזמן ובזהות',
	'orientation_in_place': 'התמצאות במקום',
	'orientation_in_time': 'התמצאות בזמן',
	'original': 'מקור',
	'osek': 'מספר ע.מ.',
	'other': 'אחר',
	'other_meeting': 'אחר',
	'otp': 'קוד אימות',
	'otp_token': 'קוד אימות',
	'otp-needed': 'יש למלא את הסיסמא החד פעמית',
	'otp_needed': 'יש למלא את הסיסמא החד פעמית',
	'out_of': 'מתוך',
	'output': 'פלט',
	'ovelapping_followupseries': 'פגישות חופפות',
	'owner': 'בעלים',
	'p': 'מרשם',
	'page': 'דף',
	'page_not_found': 'העמוד לא נמצא',
	'paginate_normal': 'ברירת מחדל לעימוד',
	'paid': 'שולם',
	'paid_by': 'נתקבל מ',
  'paid_by1' : 'שולם ע"י',
  'paid_by3' : 'שולם ב',
  'client_paid' : 'ביצע תשלום.',
	'paid_by_invoice': 'שולם במסמך',
  'paid_by_finresource' : 'שולם במסמך',
	'part': 'נוכחות חלקית',
	'part_configuration': 'הגדרות חשובות',
	'password': 'סיסמה',
	'password_change': 'שינוי סיסמה',
	'password_confirmation': 'אישור סיסמה',
	'password_current': 'סיסמה נוכחית',
	'password_new': 'סיסמה חדשה',
	'password_new_confirmation': 'סיסמה בשנית',
	'password_old': 'סיסמה קודמת',
	'password_reset': 'איפוס סיסמה',
	'password_successfully_updated': 'הסיסמה עודכנה בהצלחה',
	'passwords_not_matching': 'הסיסמא שלך וסיסמת האימות לא תואמות',
	'path': 'אמצעי קשר מסמך',
	'patient': 'מטופל',
	'patient_file': 'תיק מטופל',
	'patient_contact': 'מטופל/איש קשר',
	'patients_plus': 'מטופלים ועוד',
	'patient_contact_or_meeting': 'עם מי נפגשים?',
	'patient_data': 'פרטי המטופל',
	'patient_details': 'פרטי מטופל',
	'patient_name': 'שם מלא',
	'patient_or_contact': 'מטופל/איש קשר',
	'patient_or_payor': 'מטופל/גורם מממן',
	'patient_page': 'עמוד מטופל',
	'patient_related_tables': 'טבלאות הקשורות למטופלים',
	'patient_type': 'סוג מטופל',
	'patients': 'מטופלים',
	'payorpatients': 'מטופלים',
	'patients_explanation': '<h5><ul>  <li>רשימת מטופלים.</li><li>חיפוש מטופל ספציפי.<li/><li>קליטת מטופל חדש.</li><li>עדכון מטופל קיים.</li></ul></h5>',
	'patients_list': 'רשימת מטופלים',
	'patients_medications': 'תרופות שנלקחות על ידי המטופל',
	'patienttype': 'סוג מטופל',
	'patienttype_id': 'סוג מטופל',
	'patienttypes': 'סוגי מטופל',
	'pay_page': 'עמוד התשלום',
	'pay_with_bitcoin': 'שלמו עם ביטקוין',
	'payed': 'שולם',
	'paying': 'משלם',
	'paying_method': 'אמצעי תשלום',
	'payment': 'תשלום',
	'payment_description': 'תשלום עבור שימוש בתוכנת My-cliniq',
	'payment_details': 'לתקופה',
  'in_period' : 'בתקופה',
  'there_were' : 'התקיימו',
  'there_was_one': 'התקיימה פגישה אחת',
	'payment_failed': 'התשלום נכשל,',
	'payment_failed1': ' כדאי לנסות שוב לאחר רענון העמוד',
	'payment_method': 'אופן תשלום',
	'payment_mode': 'אופן תשלום',
	'payment_report': 'דוח הכנסות',
	'payment_successful': 'התשלום בוצע בהצלחה! ',
	'payment_successful1': 'חשבונית תישלח אליך בדוא"ל. ',
	'payment_successful2': 'ניתן לראות את התשלום בעמוד המשתמש שלך.',
	'payment_type': 'אופן תשלום',
	'paymentapp': 'אפליקציה',
	'paymentapp_id': 'אפליקציה',
	'paymentmethod': 'אופן תשלום',
	'payments': 'הכנסות',
	'payor': 'גורם מממן',
	'payor_name': 'גורם מממן',
	'payor_id': 'גורם מממן',
	'payor_details': 'פרטים נוספים',
	'payorprice': 'מחירון',
	'payorprices': 'מחירון',
	'payorprices_short': 'מחירון',
	'payors': 'גורמים מממנים',
	'paypal': 'פייפאל',
	'pdf': 'הורד כ-pdf',
	'per_month': ' לחודש',
	'per_year': ' לשנה',
	'percent': 'אחוז',
	'percent_for_calculation': 'אחוז לחישוב',
	'percent_for_tax': 'אחוז לחישוב מסים',
	'percent_for_vat': 'אחוז לחישוב מע"מ',
	'percentage': 'אחוז',
	'period': 'תקופה',
	'permanent': 'קבוע',
	'permanent_usag': 'שימוש קבוע',
	'permanent_usage': 'שימוש קבוע',
	'permanent_remarks': 'הערות קבועות',
	'personal_details': 'פרטים אישיים',
	'personal_memorandum': 'תרשומת אישית',
	'perut_on_bkmvdata': 'פרוט סך סוגי הרשומה שנוצרו בקובץ',
	'perut_tochna_vetaarich': 'פירוט תוכנה ותאריך הפקה',
	'phone': 'טלפון',
	'phone-whatsapp': 'טלפון/ווטסאפ ',
	'phone_choose_or_insert_new': 'בחירת טלפון או הכנסת חדש',
	'phone_description': 'תיאור טלפוני',
	'phone_mobile': 'טלפון נייד',
	'phone_mobile_invalid': 'טלפון נייד לא תקין',
	'phones': 'טלפונים',
	'photo': 'תמונה',
	'photos': 'תמונות',
	'place_new_payment': 'לביצוע תשלום ושינוי פרטי אשראי',
	'placeholder_title': 'translation',
	'please_active_your_account_from_the_activation_email': 'ברגעים אלה ממש נשלחה אליך הודעה בדוא"ל לאישור החשבון',
	'please_approve_saving_due_to_the_following_overlaping_followups': 'Please approve saving the new followup with exceptions due to the following overlaping followups:',
	'please_choose_name_column': 'חובה לבחור "שם" כדי לאפשר את היבוא',
	'please_choose_at_least_one': 'יש לסמן כתובת דוא"ל. ניתן להוסיף כתובת דוא"ל חדשה בשורה הראשונה',
	'please_click_here_to_go_to_form_to_reset_password': 'אישור הבקשה יאפשר למטופל/ת לאפס את הסיסמא.',
	'please_click_here_to_go_to_form_to_verify_patient': 'יש ללחוץ כאן כדי להיכנס ולראות במי מדובר ולאשר את הבקשה',
	'please_click_on_the_link_below_to_complete_verification_of_your_email': 'ברוך בואך לתוכנת My-cliniq,     <br>התוכנה הטובה בישראל לניהול קליניקה.<br>   <br>    להפעלת החשבון נא ללחוץ על הקישור המצורף             ',
  'please_click_on_the_link_below_to_register' : 'ליצירת החשבון יש להקליק על הקישור המצורף',
  'please_click_on_the_link_below_to_join' : 'להצטרפות כעמית.ה יש להקליק על הקישור המצורף',
  'please_click_on_the_link_below_to_receive_patient_details' : 'לקבלת הפרטים שמולאו ע"י המטופל יש להקליק כאן ולעבור דרך הכניסה למערכת',
  'please_click_to_change': 'יש ללחוץ כאן לשינוי',
	'please_contact_support_for_assistance_at:': 'יש ליצור קשר עם טכנאי המערכת',
	'please_enter_the_verification_code_that_was_sent_to': 'יש למלא את קוד האימות שנשלח למספרך',
	'please_enter_the_verification_code_from_the_app': 'יש למלא את קוד האימות המופיע באפליקציית האימות שלך',
	'please_enter_your_email_address._We_will_send_you_an_email_with_instructions_to_reset_your_password': 'יש להכניס את כתובת הדוא"ל שלך. אנו נשלח לך הוראות לאיפוס הסיסמא',
	'please_enter_your_email_address._we_will_send_you_an_email_with_instructions_to_reset_your_password': ' יש להזין כאן את כתובת הדוא"ל שלך, ואנו נשלח לך הוראות איפוס סיסמה במייל',
	'please_enter_your_email_address_we_will_send_you_an_email_to_user': ' יש להזין כאן את כתובת הדוא"ל שלך, בקשתך תטופל אך לא מייד, אלא רק לאחר אישור מהמטפל/ת שלך',
	'please_find_attached_your_document': 'מצורף עבורך מסמך.',
  'please_find_attached_your_finresources' : 'מצורפים עבורך מסמכים',
	'please_find_attached_your_invoice': 'מצורפת עבורך חשבונית.',
	'please_find_attached_your_receipt': 'מצורפת עבורך קבלה.',
	'please_find_attached_your_prescription': 'מצורף עבורך מרשם לתרופה',
	'please_find_attached_your_proforma': 'מצורף עבורך חשבון עסקה.',
	'please_find_attached_your_patient_proforma': 'רצ"ב רשימת הפגישות והחיוב התקופתי.',
  'your_patient_proforma' : 'הודעה על חיוב חודש קודם.',
	'please_follow_the_instructions_we_sent_to': 'דוא"ל לאיפוס סיסמה נשלח לאמצעי קשר שביקשת',
	'please_insert_only_number': 'בבקשה להכניס מספרים בלבד',
	'please_leave_contact_details': 'נשמח אם תכתבו לנו איך ליצור איתכם קשר ',
	'please_login_or_register_to_proceed': 'בבקשה הכנס או הרשם להמשך',
	'please_note_date_past': 'שימו לב שהפגישה שאתם עורכים או מוסיפים, היא בעבר. ביומן הפעולות, הפעולה תירשם בתאריך הנוכחי',
	'please_note_num': 'בשינוי מספר ע.מ., יש לשנות גם את כותרת החשבונית והחשבון',
	'please_resend_me_activation_email': 'בבקשה שילחו לי דוא"ל חוזר לאימות',
	'please_select': 'לבחירה',
	'please_select_plan': 'בחרנו עבורך תכנית תמחור הוגנת ופשוטה, כזו שתוכל להתאים לצרכיך ולדרך העבודה שלך. בחירת התוכנית תאפשר לך כניסה מהירה והשתלבות מיידית במערכת.',
	'please_wait_while_we_create_your_account': 'החשבון שלך נוצר ברגעים אלה, תודה על ההמתנה',
	'plus_vat': ' פלוס מע"מ',
	'postponed': 'נדחה',
	'posts': 'מאמרים',
	'prescription': 'מרשם',
	'add_prescription': 'מרשם',
	'prescription_header': 'כותרת מרשם',
	'prescription_no': 'מרשם מס.',
	'prescription_preview': 'מרשם - תצוגה מקדימה',
	'prescription_template': 'תבנית למרשם',
	'prescriptions': 'מרשמים',
	'preview': 'תצוגה מקדימה',
	'to_preview': 'לתצוגה מקדימה',
	'previous': 'הקודם',
	'previous_month': 'החודש הקודם',
	'previous_page': 'היסטורית גלישה באתר',
	'previous_password': 'הסיסמא צריכה להיות שונה מסיסמאות העבר שלך',
	'price': 'מחיר',
	'price_bought': 'המחיר שקנינו את המוצר',
	'price_for_patient': 'מחיר למטופל',
	'price_for_meeting': 'תשלום למטפל',
	'price_in_us_dollar': 'מחיר בדולרים ( ארצות הברית )',
	'price_in_us_dollars': 'המחיר הוא בדולרים של ארצות הברית',
	'price_paid': 'המחיר ששולם',
	'price_patient': 'מחיר למטופל',
	'price_payor': 'מחיר למממן',
	'price_update_will_change_the_whole_serie_and_followup_type': '* עדכון מחיר ישנה את המחיר לכל הפגישות הבאות מאותו סוג פגישה.',
	'pricing': 'מחירים',
	'pricing_and_patient_type': 'מחיר וסוג מטופל',
	'print': 'הדפסה',
	'print_and_download': 'אישור ושמירת PDF',
	'print_copy': 'הדפסת העתק',
	'print_invoice': 'הדפסת חשבונית',
	'print_proforma': 'הדפסת חשבונות עסקה',
	'privacy': 'תנאי פרטיות',
	'privacy_policy': 'מדיניות הפרטיות של האתר.',
	'private': 'פרטי',
	'profession': 'מקצוע',
	'profitloss': 'רווח והפסד',
	'profitloss_report': 'דוח רווח והפסד',
	'profile': 'פרופיל',
	'profile_photo': 'תמונת פרופיל',
	'proforma': 'חשבון עסקה',
	'proforma_to_patient': 'בקשת תשלום',
	'proforma_id': 'קוד חשבונית',
	'proforma_num': 'מספר חשבון עסקה התחלתי',
	'justinvoice_num': 'מספר חשבונית מס התחלתי',
	'receipt_num': 'מספר קבלה התחלתי',
	'proforma_preview': 'הצגת חשבון עסקה לפני הדפסה ושמירה',
	'proforma_short': 'ח.עסקה',
	'proformalines': 'שורות החשבונית',
	'proformas': 'חשבונות עסקה',
	'proformas_by_date': 'הכנסות לפי תאריכים',
	'proformas_by_month': 'הכנסות - חודשי',
	'proformas_by_year': 'הכנסות - שנתי',
	'proformas_report': 'רשימת חשבונות עסקה',
	'justinvoices_report': 'רשימת חשבוניות מס מול קבלות',
	'justinvoices': 'חשבוניות מס',
	'program': 'תוכנית',
	'program_id': 'תוכנית',
	'programs': 'תוכניות',
	'pturot': 'עסקאות פטורות או בשיעור 0',
	'public_site': 'my-cliniq.com',
	'publice': 'שיווק',
	'purchase': 'תשלום',
	'purchase_details': 'פירוט רכישות',
	'purchase_history': 'קניות קודמות',
	'purchase_id': 'מזהה יחודי למכירה',
	'purchases': 'תשלומים',
	'purchases_summary': 'סיכום רכישות',
	'put_this_purchase_under': 'העבר קניה זו למשתמש אחר',
	'quantity': 'כמות',
	'question': 'שאלה',
	'questions': 'שאלות',
	'read_more': 'המשך',
	'recent': 'עכשוי',
	'receipt_doc': 'קבלה',
	'recipient': 'נמען',
	'recipients': 'נמענים',
	'record_code': 'קוד רשומה',
	'record_description': 'תיאור רשומה',
	'refresh': 'רענון',
	'reg': 'הנדון',
	'reg_no': 'מספר רישום',
	'register': 'הרשמה',
	'register_free': 'חודש ניסיון מתנה',
	'register_month': 'למשתמש.ת לחודש',
	'register_price': '₪79 + מע"מ ',
	'register_text1': 'ללא מכסת מטופלים',
	'register_text2': 'ללא הגבלת נפח אכסון',
	'register_text3': 'ללא עלויות חבויות',
	'register_text4': 'כולל חשבוניות',
	'registration_successful': 'נרשמת בהצלחה!',
	'regular': 'פגישה',
	'reject': 'דחיה',
	'related_data': 'נתונים למטופל',
	'remarks': 'הערות',
	'remark': 'הערה',
	'no_answer': 'ללא תשובה',
	'remember password :': 'שמירת סיסמה',
	'reminder': 'תזכורת',
	'reminder_description': 'תיאור תזכורת',
	'reminders': 'תזכורות',
	'remove': 'הסרה',
	'remove_invoiceline': 'מחיקת שורת חשבונית',
	'remove_proformaline': 'מחיקת שורה',
	'renew': 'חידוש',
	'repayment_date': 'תאריך תשלום',
	'repeat': 'חזרה',
	'repeat_days': 'מספר ימים לחזרה',
	'replace_photo': 'החלפת תמונה',
  'reply': 'תגובה לתגובה',
  'to_reply_reply_to' : 'כדי לענות לדוא"ל הזה, יש לענות באמצעות "השב ל..."',
  'to_reply_reply_to1' : 'כדי לענות לדוא"ל הזה, יש לענות באמצעות <b/>השב ל<b>',
	'report': 'דוח',
	'report_problem': 'דיווח על תקלה',
	'reports': 'דוחות',
	'reprint': 'שחזור העתק',
	'requested_time': 'השעה המבוקשת:',
	'resend_activation': 'שליחה חוזרת של דוא"ל לאימות',
	'reset': 'איפוס',
	'reset_password': 'איפוס סיסמה',
	'reset_password_for': 'איפוס סיסמה באתר',
	'reset_password_now': 'איפוס סיסמה עכשיו',
	'result': 'תוצאה',
	'role': 'תפקיד',
	'roles': 'תפקידים',
	'route_of_admin': 'אופן נטילה',
	'row_actions': 'פעולות',
	'actions': 'פעולות',
	'saturday': 'שבת',
	'save': 'שמירה',
	'save_and_select_template': 'שמירה ובחירת תבנית',
	'save_as_draft': 'שמירה כטיוטה',
	'save_failed': 'השמירה נכשלה',
	'save_followupserie_with_overlap_exceptions': 'Save followup with overlap exceptions',
	'save_only': 'שמירת טיוטה',
	'save_tempalte': 'שמירת תבנית',
	'saved_tempalte': 'התבנית נשמרה',
	'save_with_exceptions': 'שמירה ללא הכפילויות',
	'saved_successfully': 'נשמר בהצלחה',
	'saving': 'מעדכן…',
	'schema': 'מבנה',
	'search': 'חיפוש',
	'search_for_user': 'חיפוש משתמשים',
	'search_patient': 'חיפוש מטופל',
	'seating': 'ישיבה',
	'second_email': 'דוא"ל נוסף',
	'second_phone': 'טלפון נוסף',
	'see_all': 'לראות את הכל',
	'see_all_patient_images': 'גלריית תמונות',
	'see_document': 'צפייה במסמך',
	'select': 'בחירה',
	'select_a_headture': 'בחירת תבנית',
	'select_a_template': 'טמפלייט',
	'select_all': 'יש לבחור את כל האתרים',
	'select_all_fields': 'יש לבחור הכל',
	'select_email_phone': 'בחירת דוא"ל וטלפון',
	'select_paln': 'בחירת תוכנית',
	'select_plan': 'בחירת תוכנית',
	'select_to_whom_first': 'יש לבחור תחילה, עבור מי החשבונית',
	'select_a_password': 'יש להזין כאן סיסמא חדשה משלך, ולאמת אותה',
	'selected': 'נבחר',
	'self_collect': 'איסוף עצמאי',
	'self_oriented': 'מודעות עצמית',
	'self_reminder': 'תזכורת למטפל',
	'send': 'נשלח',
	'send_by_email': 'שליחה בדוא"ל',
	'send_emails': 'שליחת דוא"ל',
	'send_original_to_user': 'לשלוח מקור למשתמש',
	'send_to_this_email_every': 'לשלוח כל מסמך?',
	'send_users_email': 'משלוח דוא"לים למשתמשים',
	'sendgrid_status': 'מצב שליחה',
	'sending_a_text_message_to_your_mobile_phone_with_a_verification_code': 'ברגעים אלה נשלח למכשיר הנייד שלך קוד האימות',
	'sent_successfully': 'נשלח בהצלחה',
	'sent_to': 'נשלח בדוא"ל',
	'series_2weekly': 'שבועיים',
	'series_3weekly': '3 שבועות',
	'series_daily': 'כל יום',
	'series_exception': 'שינוי חד פעמי',
	'exception': 'שינוי חד פעמי',
	'series_for_hashav_contact': 'סדרה לחשבשבת איש קשר',
	'series_for_hashav_patient': 'סדרה לחשבשבת מטופל',
	'series_for_hashav_payor': 'סדרה לחשבשבת גורם משלם',
	'series_monthly': 'כל חודש',
	'series_repeat': 'חזרה',
	'series_weekly': 'כל שבוע',
	'server_error': 'עקב סגירת המחשב האתר צריך להתרענן כדי לחזור לפעילות תקינה!',
	'set': 'סט',
	'set_contact_types': 'הגדרת סוגי איש קשר',
	'set_default_length': 'ברירת מחדל למשך פגישה',
	'set_followup_types': 'הגדרת סוגי פגישה',
	'set_patient_types': 'הגדרת סוגי מטופל',
	'setting': 'הגדרות',
	'setting_explanation': 'הסבר על הגדרות',
	'settings': 'הגדרות',
	'short': 'קצרה',
	'show': 'הצגת',
	'show_manual_charge': 'הצגת אפשרות של חיוב ידני/אחר',
	'show_payment_details': 'הצגת פרטי תשלום',
	'invoice_per_followup': 'שיוך חשבוניות לפגישות',
	'show_payment_details_sum_in_invoice': 'הצגת פרטי תשלום וסך לתשלום בחשבונית',
	'show_signature_header': 'כותרת וחתימה',
	'show_versions': 'גרסאות',
	'show_versions_on_followup': 'הצגת גרסאות במעקב',
  'show_personal_memorandum': 'תרשומת אישית',
  'show_personal_memorandum_on_mobile': 'הצגת תרשומת אישית בתצוגת נייד',
	'show_patient_id': 'הצגת מספר מטופל בחיפוש',
	'show_insert_row': 'הצגת שורת קליטה',
	'sign_up': 'יצירת חשבון',
	'signature': 'חתימה',
	'signature_text': 'חתימה',
	'simulator': 'סימולטור',
	'simulator__ip': 'איפי',
	'simulator__token': 'תוקן',
	'simulator__user_id': 'משתמש אידי',
	'simulator__user_name': 'שם משתמש',
	'simulators': 'סימולטורים',
	'single': 'רווק/ה',
	'site_description': 'ניהול קליני וכספי של מרפאה',
	'site_kew_words': 'psychology, psychiatry, health care, clinic, קליניקה, פסיכולוגיה',
	'site_logo': 'לוגו האתר',
	'site_name': 'my-cliniq.com',
	'site_slogan': 'לנהל קליניקה, בקלילות',
	'site_team': 'צוות My-Cliniq',
	'size': 'גודל',
	'sms': 'מסרון',
  'smses' : 'מסרונים',
	'social_security': 'מקדמות ביטוח לאומי',
	'sorry,_validation_error': 'אירעה שגיאת אימות בטופס',
	'sorry,_you_have_not_made_any_orders_yet': 'למרבה הצער עדיין לא ביצעת הזמנה באתר',
	'sorry,no_result_match': 'מצטערים, לא מצאנו תוצאה מתאימה',
	'sorry_error': 'סליחה קרתה תקלה במערכת, הפרטים נשלחו לתמיכה שלנו, מוזמנים להוסיף כמה מילים או פרטים ליצירת קשר',
	'sort': 'מיון',
	'sort_by': 'מיון לפי',
	'source_doc_types': 'סוגי מסמך',
	'start': 'התחלה',
	'start_date': 'תאריך התחלה',
	'start_date_end_date': 'תאריך התחלה - תאריך סיום',
	'start_on': 'מתחילה בתאריך ',
	'start_paying_first': 'סיום תקופת הרצה My-Cliniq ותחילת תשלום',
	'start_paying_second': 'סיום תקופת הרצה My-Cliniq ותחילת תשלום - הודעה שניה',
	'ot_payment': 'תחילת תשלום עבור My-Cliniq',
	'ot_second_payment': 'תחילת תשלום עבור My-Cliniq - הודעה שניה',
	'start_time': 'זמן התחלה',
	'start_time_hour': 'שעת התחלה',
	'end_time_hour': 'שעת סיום',
	'day_time_range': 'יש לבחור את טווח השעות הפנוי ב',
	'start_typing_here': 'הכנסת טקסט',
	'status': 'סטטוס',
	'status_name': 'שם סטטוס',
	'statuses': 'סטטוסים',
	'stock': 'מלאי',
	'stop_auto_renew': 'הפסקת חידוש אוטומטי',
	'stop_repeat_after': 'הפסק אחרי מספר חזרות',
	'street': 'רחוב',
	'style': 'סגנון',
	'sub_total': 'סיכום ביניים',
	'subject': 'נושא',
	'submit': 'שליחה',
	'submit_invitation': 'שליחת הזמנה',
	'submit_question': 'קליטת שאלה',
	'submit_report': 'הצגה',
	'success': 'הצלחה',
	'suicidality': 'אובדנות',
	'suicidality_desc': 'נשללה במפורש, ללא עדות לסיכון אובדני',
	'sum': 'סכום',
	'sum_of_line': 'סכום השורה',
	'expense_without_vat': 'סכום ללא מע"מ',
	'sums_by': 'סך לפי',
	'sunday': 'ראשון',
  'su': 'א', 'mo': 'ב', 'tu': 'ג', 'we': 'ד', 'th': 'ה', 'fr': 'ו', 'sa': 'ש',
	'supplier': 'ספק',
	'supplier_id': 'ספק',
	'supplier_number': 'ספק',
	'supplier_invoice': 'חשבונית ספק',
	'supplier_name': 'שם ספק',
	'suppliers': 'ספקים',
	'suspended': 'מושהה',
	'table': 'טבלה',
	'table_of_diagnosis': 'אבחנות',
	'table_of_lab_tests': 'טבלת בדיקות והדמיות',
	'table_of_medications': 'טבלת תרופות',
	'table_of_nondrugs': 'טבלת טיפולים',
	'tag_updated': 'התג עודכן',
	'tag': 'תגית',
	'tags': 'תגים',
	'taken_for': 'נלקח למשך',
	'takes_place': 'תופס מקום',
	'tax': 'מס',
	'tax_deductible': 'מוכר למס - (כולל מע"מ לא מוכר)',
	'expense_recognized_for_tax': 'מוכר למס - (כולל מע"מ לא מוכר)',
	'tax_deductible2': 'מוכר למס    - (ללא מע"מ לא מוכר)',
	'expense_recognized_for_tax2': 'מוכר למס    - (ללא מע"מ לא מוכר)',
	'tax_details': 'פרטי מס',
	'tax_doc': 'חשבונית מס',
	'tax_number': 'מס\' תיק מס',
	'tax_on_transcations': 'המס על עסקאות',
	'tax_percent': 'אחוז מוכר למס',
	'expensetype_vat_percent': 'אחוז מוכר למע"מ',
	'expense_vat_recognized': 'אחוז מוכר למע"מ',
	'tax_receipt_doc': 'חשבונית מס / קבלה',
	'tax_refund_doc': 'חשבונית זיכוי',
	'tax_tsumot': 'מס תשומות על ציוד ונכסים קבועים',
	'tax_tsumot_other': 'מס תשומות על אחרות',
	'tax_year': 'שנת המס עליה הופקו הנתונים',
	'taxes': 'מסים',
	'taxes_calc': 'חישוב מסים',
	'taxes_report': 'מסים',
	'temp_announcement': 'השימוש במערכת הוא חינם לתקופה של חודש אחד.<br>        בתום החודש נשלח לך הודעה על סיום התקופה ובקשה להמצאת אמצעי תשלום להמשך שימוש.<br>    <br>        נשמח להדריך אותך בצעדים הראשונים ב-My-Cliniq.  <br>        <br>             ניתן להיעזר בסרטוני הדרכה <a href="https://www.youtube.com/channel/UCmXNr4tXUSYK_R10Mz6B5jg/featured" target="_blank">בערוץ My-cliniq</a> <br>    <br>    ניתן לפנות בכל שאלה <br> בדוא"ל <a href="mailto:support@my-cliniq.com">support@my-cliniq.com</a> <br>    או בווטסאפ ל- 054-4244408<br>    <br>        התוכנה נכתבה עבורך. נשמח לשמוע כל רעיון, בקשה, צורך או השגה<br> וכמובן, אנא דווחו מייד על כל בעיה או תקלה<br>    <br> בהצלחה               ',
  'temp_announcement_sub_user' : '            נשמח להדריך אותך בצעדים הראשונים ב-My-Cliniq.          <br>             ניתן להיעזר בסרטוני הדרכה <a href="https://www.youtube.com/channel/UCmXNr4tXUSYK_R10Mz6B5jg/featured" target="_blank">בערוץ My-cliniq</a>     <br>    מוזמנים לפנות בכל שאלה, <br> בדוא"ל: <a href="mailto:support@my-cliniq.com">support@my-cliniq.com</a> <br>    או בווטסאפ: 054-4244408<br>    <br>        התוכנה נכתבה עבורך. נשמח לשמוע כל רעיון, בקשה, צורך או השגה<br> וכמובן, אנא דווחו מייד על כל בעיה או תקלה    <br> בהצלחה!               ',

  'temp_announcement_potential': 'השימוש במערכת הוא חינם לתקופה של חודשיים.<br>        בתום החודשיים נשלח לך הודעה על סיום התקופה ובקשה להמצאת אמצעי תשלום להמשך שימוש.<br>    <br>        נשמח להדריך אותך בצעדים הראשונים ב-My-Cliniq.  <br>        <br>             ניתן להיעזר בסרטוני הדרכה <a href="https://www.youtube.com/channel/UCmXNr4tXUSYK_R10Mz6B5jg/featured" target="_blank">בערוץ My-cliniq</a> <br>    <br>    ניתן לפנות בכל שאלה <br> בדוא"ל <a href="mailto:support@my-cliniq.com">support@my-cliniq.com</a> <br>    או בווטסאפ ל- 054-4244408<br>    <br>        התוכנה נכתבה עבורך. נשמח לשמוע כל רעיון, בקשה, צורך או השגה<br> וכמובן, אנא דווחו מייד על כל בעיה או תקלה<br>    <br> בהצלחה               ',
	'tempalte_number': 'תבנית',
	'template': 'בחירת תבנית',
	'template.document': 'טמפלייט',
	'template_title': 'כותרת טמפלייט',
	'terms': 'תנאים',
	'terms_of_service': 'תנאי השימוש, ',
  'agree_to_terms' : 'יש להסכים לתנאי השימוש',
	'test': 'שאלון',
	'new_test': 'שאלון חדש',
	'test_name': 'שם שאלון',
	'test_submit': 'קליטת שאלון',
	'tests': 'שאלונים',
	'teudat_rishum': 'מספר תעודת הרישום',
	'text_onetime_paying_first_1': 'תקופת ההרצה של  My-Cliniq הסתיימה.',
	'text_onetime_paying_first_3': 'לאור זאת, הגיע הזמן לשלם עבור השימוש בתוכנה. עלותה החודשית של My-Cliniq תעמוד על ',
	'text_onetime_paying_first_4': '₪ פלוס מע"מ. אנו מאמינים שמחיר זה משקף באופן הוגן את התועלת שמביאה התוכנה.',
	'text_onetime_paying_first_5': 'למעבר מאובטח לעמוד התשלום >',
	'text_onetime_paying_first_6': 'כתמיד, אנו פתוחים לרעיונות, בקשות, הערות והארות.',
	'text_paying_1': 'אנחנו שמחים לעדכן שתקופת ההרצה של My-Cliniq עומדת לקראת סיום.',
	'text_paying_2': 'לאור זאת, אנו מתחילים בגביית תשלום חודשי עבור השימוש בתוכנה. עלותה החודשית של My-Cliniq תעמוד על ₪79 פלוס מע"מ. אנו מאמינים שמחיר זה משקף באופן הוגן את התועלת שמביאה התוכנה.',
	'text_paying_4': 'כתמיד, אנו פתוחים לרעיונות, בקשות, הערות והארות.',
	'text_paying_first_1': 'תם חודש הניסיון שהחל עם הרשמתך ל-<b>My-Cliniq</b>.',
	'text_paying_first_2': 'אנו מקווים שניצלת את התקופה כדי להתרשם מהאופן שבו <b>My-Cliniq</b> מסוגלת לחולל מהפכה בניהול הקליניקה שלך.',
	'text_paying_first_2a': ' בין אם מדובר <b>ברשומות רפואיות</b> דיגיטליות, <b>ביומן רופא המאושר על ידי רשות המסים</b> ומסונכרן עם מאזן החוב, או מערכת מתקדמת של <b>חשבוניות דיגיטליות</b> - אנחנו כאן כדי לחסוך בזמן,<br> לקצץ בבירוקרטיה ולהנמיך את כל הרעש שמסביב, כדי לאפשר לך להתרכז במה שחשוב באמת - הטיפול.',
	'text_paying_first_3': '<b>עלותה החודשית של <b>My-Cliniq</b> היא',
	'text_paying_first_4': ' פלוס מע"מ.</b> אנו מאמינים שמחיר זה משקף באופן הוגן את התועלת שמביאה התוכנה ואת המאמצים שהשקענו בעיצוב <br>חוויית שימוש ייחודית ומהפכנית.</br> <b>אם אהבת, נשמח לצרף אותך למעגל הלקוחות שלנו.',
	'text_paying_first_5': 'למעבר מאובטח לעמוד התשלום >',
	'text_paying_first_6': 'כתמיד, אנו פתוחים לרעיונות, בקשות, הערות והארות.',
	'text_paying_second_1': 'אנחנו מזכירים לך שתם חודש הניסיון שהחל עם הרשמתך ל-<b>My-Cliniq</b>.',
	'text_paying_second_2': 'אנו מקווים שניצלת את התקופה כדי להתרשם מהאופן שבו <b>My-Cliniq</b> מסוגלת לחולל מהפכה בניהול הקליניקה שלך.',
	'text_paying_second_2a': ' בין אם מדובר <b>ברשומות רפואיות</b> דיגיטליות, <b>ביומן רופא המאושר על ידי רשות המסים</b> ומסונכרן עם מאזן החוב, או מערכת מתקדמת של <b>חשבוניות דיגיטליות</b> - אנחנו כאן כדי לחסוך בזמן,<br> לקצץ בבירוקרטיה ולהנמיך את כל הרעש שמסביב, כדי לאפשר לך להתרכז במה שחשוב באמת - הטיפול.',
	'text_paying_second_3': '<b>עלותה החודשית של <b>My-Cliniq</b> היא',
	'text_paying_second_4': ' פלוס מע"מ.</b> אנו מאמינים שמחיר זה משקף באופן הוגן את התועלת שמביאה התוכנה ואת המאמצים שהשקענו בעיצוב <br>חוויית שימוש ייחודית ומהפכנית.</br> <b>אם אהבת, נשמח לצרף אותך למעגל הלקוחות שלנו.',
	'text_ot_paying_first_1': 'כפי שהודענו, תקופת ההרצה של <b>My-Cliniq</b> הסתיימה בסוף חודש מרץ.',
	'text_ot_paying_2': 'מעתה השימוש בתוכנה יהיה כרוך <b>בתשלום חודשי של',
	'text_ot_paying_2a': ' פלוס מע"מ.</b>',
	'text_ot_paying_3': 'מחיר זה מקנה שימוש חופשי בשירותי התוכנה - ללא הגבלה וללא התחייבות. העלות כוללת הנפקת חשבוניות דיגיטליות, יומן רופא המאושר על ידי רשות המסים, מערכת ניהול פגישות ורשומות רפואיות, ומגוון דרכים אחרות לייעול הניהול השוטף של קליניקה.',
	'text_ot_paying_4': 'רצינו לנצל את ההזדמנות כדי להודות לך על ההשתתפות בתקופת הפיתוח הראשוני. למרות ש-<b>My-Cliniq</b> כבר לא בהרצה, אנחנו נותרים מחויבים להמשך הפיתוח ולהוספת כלים חדשים. זוהי סופה של תקופה, אך גם תחילתה של דרך חדשה.',
	'text_ot_paying_second_1': 'כפי שהודענו, תקופת ההרצה של <b>My-Cliniq</b> הסתיימה בסוף חודש מרץ, זוהי הודעה שניה.',
	'text_payment': 'תודה על התשלום עבור השימוש ב-',
	'text_report': 'תיעוד מעקבים',
	'text_suspended': 'סיום תקופת הרצה My-Cliniq ותחילת תשלום - הודעה לפני השעיה',
	'text_suspended_1': 'כפי שהודענו לך בדוא"לים קודמים, תקופת ההרצה של My-Cliniq הסתיימה.',
	'text_suspended_2': 'עלותה החודשית של My-Cliniq תעמוד על ',
	'text_suspended_3': '₪ פלוס מע"מ. אנו מאמינים שמחיר זה משקף באופן הוגן את התועלת שמביאה התוכנה.',
	'text_suspended_4': 'למעבר מאובטח לעמוד התשלום >',
	'text_to_translate_in_english_invoice': 'טקסטים לתרגום להפקת חשבונית באנגלית',
	'textvalue': 'ערך טקסטואלי',
	'thank_you_for_your_registration': 'תכף מסיימים',
	'see_you_after_registration': 'נתראה בקרוב,',
	'thank_you_for_your_registration1': 'כמעט סיימנו',
	'registration_wait': 'כל שנשאר הוא להזין את מספר האימות שנשלח אליך לנייד',
	'thanks_alot': 'תודה רבה',
	'thanks_we_have_received_your_message': 'תודה, קיבלנו את ההודעה',
	'the_original_message': 'ההודעה המקורית',
	'therapist': 'מטפל/ת',
	'therapists': 'מטפלים/ות',
	'assoc_sub_user_ids': 'זיהוי מטפל/ת',
  'there_are_no_followups': ' בתאריך זה אין עדיין מעקבים',
	'there_are_no_followups_past': 'לא מופיעים מעקבים בתאריך זה',
	'there_are_no_followups_future': 'בתאריך זה <br/>עדיין אין מעקבים',
	'there_are_no_followups_but_fus_future': 'תיעוד המעקבים בתאריך זה יתאפשר <br/>ביום הפגישה או אחריו',
	'there_are_no_followups_present': 'אין מעקבים היום,<br/> אפשר לצאת לטיול',
	'there_are_no_followups_click_to_set': 'להוספת פגישה חדשה: <br/>יש ללחוץ על נקודה ריקה <br/>ביומן מימין',
	'there_is_a_patient_by_that_name': 'יש כבר מטופל בשם הזה.                              האם תרצו להמשיך ולקלוט מטופל נוסף?',
	'there_is_no_header': ' אין למסמך כותרת ברירת מחדל עם פרטיך, יש לקלוט כותרת וחתימה ולציין שהיא ברירת מחדל למסמך',
	'this_action_requires_login': 'פעולה זו דורשת כניסה לאתר',
	'this_document_has_been_signed': 'המסמך הזה ננעל ולא ניתן לעריכה, האם תרצו להשתמש בו כתבנית למסמך חדש?',
	'this_document_has_been_signed_use_as_template?': 'המסמך הזה הורד ונחתם, האם תרצו להשתמש בו כתבנית?',
	'this_event': 'האירוע הנוכחי',
	'this_is_from_taxes_table_from_the_user_data': 'הנתון הוא מטבלת מסים בהגדרות המשתמש',
	'this_month': 'החודש',
	'patient_exists': 'המטופל קיים במערכת',
	'this_patient_exists_here': 'המטופל משוייך כבר לטיפול הזה',
	'this_patient_exists_to_continue?': 'קיים מטופל בשם זה במערכת האם להמשיך וליצור מטופל נוסף עם שם זהה?',
	'this_week': 'השבוע',
	'this_year': 'השנה',
	'thoughtProcess': 'חשיבה',
	'thoughtProcess_desc': 'תקינה',
	'thursday': 'חמישי',
	'time': 'שעה',
	'time_between': 'המרווח הנותר הוא :',
	'time_from': 'משעה',
	'time_on_holiday_evening': 'שעת סיום בערב חג',
	'time_to': 'עד שעה',
	'time_to_logout': 'זמן ליציאה מהמערכת',
	'time_with_breaks_overlaps_next_meeting': 'משך הפגישה הנדרש עולה על הפגישה הבאה',
	'title': 'כותרת',
	'to': 'לכבוד',
	'to_be_calculated': 'יש לחשב מס הכנסה לתשלום לאחר קיזוז ניכוי במקור',
	'to_be_done': 'לביצוע',
	'to_continue': 'להמשך',
	'to_continue_with_main': 'להמשיך למנוי הפרטי',
	'to_continue_your_subscription': 'בכדי להמשיך את המנוי שלך ',
	'to_date': 'עד תאריך',
	'to_payment': ' מעבר לתשלום',
	'to_secure_your_account': 'קוד אימות יישלח במסרון למספר זה',
	'to_terms': 'לתנאי השימוש ולמדיניות הפרטיות של האתר.',
	'today': 'היום',
	'token_used_or_not_found': 'הטוקן לא קיים',
	'toname': 'נשלח אל',
	'total': 'סה"כ',
	'total_of': 'ע"ס',
	'totalMinusvat': 'סך ללא מע"מ',
	'total_admininvoice': 'סך חשבוניות',
	'total_amount': 'כמות כוללת',
	'total_debt': 'סך חוב',
	'total_expense_recognized': 'סך הוצאות מוכרות',
	'total_expense_vat_recognized': 'סך מע"מ הוצאות מוכר',
	'total_expense_tax': 'סך מס הוצאות',
	'total_expense_vat': 'סך מע"מ הוצאות',
	'total_expenses': 'סך הוצאות',
	'total_for': 'סך',
	'total_for_payment': 'סך לתשלום',
	'total_invoice': 'סך',
	'total_items': 'סך הכל סוגים',
	'total_lm_debt': 'חוב חודש קודם',
	'total_lm_paid': 'סך שולם חודש קודם',
	'total_lm_prices': 'עלות חודש ',
	'total_lm_payor_prices': 'עלות גורם מממן לחודש ',
	'total_minus_vat': 'סך ללא מע"מ',
	'total_monthly_debt': 'חוב חודש נוכחי',
	'total_monthly_paid': 'סך שולם חודשי',
	'total_monthly_prices': 'סך חוב חודשי',
	'total_invoice_vat_exempt': 'סך עסקאות פטורות או בשיעור 0',
	'total_inc_vat': 'עסקאות חייבות (ללא מע"מ)',
	'total_paid': 'סך שולם',
	'total_prices': 'חוב כולל עד כה',
	'total_records': 'סך רשומות',
	'total_tax': 'מס לתשלום',
	'total_total_debt': 'סך חוב להיום',
	'total_total_credit': 'יתרת זכות ל',
	'total_until_lm_debt': 'סך חוב לסוף חודש ',
	'total_users': 'סה"כ משתמשים',
	'total_invoice_vat ': 'סך מע"מ',
	'total_without_vat': 'סך ללא מע"מ',
	'total_with_vat': 'סך כולל מע"מ',
	'total_yearly_debt': 'סך חוב שנתי',
	'total_yearly_paid': 'סך שולם שנתי',
	'total_yearly_prices': 'סך חוב שנתי',
	'totall': 'סכום',
	'totals': 'סך ',
	'transaction_id': 'זיהוי עיסקה',
	'treatment': 'טיפול',
	'continue_treatment': 'המשך טיפול',
	'ttt': 'טטט',
	'tuesday': 'שלישי',
	'two_months_before': 'חודשיים קודמים',
	'type': 'סוג',
	'unable_to_delete': 'אין אפשרות למחוק, ייתכן והמפתח בשימוש או שיש בטבלה רשומה אחת',
	'undo': 'לבטל',
	'unified': 'אחיד',
	'unit': 'יחידה',
	'price_cancellation': 'מחיר ביטול',
	'unit_cancellation': 'יחידה למחיר ביטול',
	'unsubscribe_message': 'דוא"ל זה נשלח אליך כי נרשמת לאתר שלנו, אם אינך רוצה להמשיך לקבל מאיתנו דוא"לים, אנא לחץ/י על הלינק והדוא"ל שלך יוסר מן הרשימה.',
	'until_date': 'עד לתאריך (כולל)',
	'update': 'עדכון',
	'update-': 'עדכון ',
	'update_calendar': 'עריכת יומן',
	'update_followup': 'עידכון מעקב',
	'update_successfuly': 'עודכן בהצלחה',
	'update_time': 'זמן עדכון',
	'update_test': 'עדכון שאלון/טופס',
	'duplicate_test': 'שכפול שאלון/טופס',
  'test_duplicated': 'השאלון שוכפל',
	'updated': 'עודכן',
	'updated_at': 'עודכן לאחרונה',
	'upload': 'העלאה',
	'upload_aborted': 'העלאת הקובץ נכשלה',
	'upload_failed': 'העלאת הקובץ נכשלה',
	'download_failed': 'הורדת הקובץ ושליחתו נכשלה',
	'upload_file': 'הוספת מסמך',
	'upload_image': 'צירוף תמונה',
	'upload_successful': 'התמונה הועלתה בהצלחה',
	'no_file_selected': 'יש לבחור קובץ',
	'uploading_new_photo_will_replace_the_current_one': 'העלת תמונה חדשה תחליף את התמונה הנוכחית',
	'upremarks': 'Upremarks',
	'url': 'כתובת אתר',
	'use_as_template': 'לשימוש כטמפלייט(תבנית)',
	'use_few_softwares': 'האם את/ה משלב/ת מספר תוכנות לניהול הקליניקה?',
	'use_software': 'האם את/ה משתמש/ת בתוכנה לניהול הקליניקה?',
	'user': 'משתמש',
	'user_configuration': 'הגדרות משתמש',
	'user_credit_card_has_expired': 'פג תוקפו של כרטיס האשראי של אחד מהמשתמשים',
	'userdetails': 'פרטי משתמש',
	'userdetails_website': 'פרטי משתמש לדף ברשת',
	'user_id': 'קוד משתמש',
	'user_invoice': 'חשבונית/קבלה',
	'user_invoice_short': 'חשבונית',
	'user_name': 'שם משתמש',
	'user_name/email_or_password_incorrect': 'שם המשתמש, הדוא"ל או הסיסמא אינם תקינים',
	'user_programs': 'התוכניות שלי',
	'user_settings_were_updated_successfully': 'ההגדרות שלך עודכנו בהצלחה',
	'user_title': 'תואר',
	'username': 'שם משתמש',
	'users': 'משתמשים',
	'users_list': 'משתמשים',
	'vacation': 'חופשה',
	'valid_from': 'תקף החל מ-',
  'new_valid_from' : 'תאריך התחלת תוקף חדש',
	'valid_to': 'סיום תוקף הערך הקודם (החדש יחושב, יום אחד אחרי)',
	'valid_to_change': 'בשינוי תוקף אחוז מס, יש לקלוט את תאריך סיום תוקף הערך הקודם ואת האחוז החדש בשורה המתאימה,תאריך התוקף של האחוז החדש יהיה יום אחרי סיום הישן כל השאר יבוצע באופן אוטמטי',
	'validation_error': 'יש שגיאה בטופס',
	'value': 'ערך מספרי',
	'value1_date': 'תאריך_ערך',
	'value_date': 'תאריך ערך',
	'vat': 'מע"מ',
	'expense_vat': 'מע"מ',
	'vat_for_payment': 'מע"מ לתשלום',
	'vat_percent': 'אחוז מע"מ',
	'vat_number': 'מספר ע.מ./ח.פ./ע.ר.',
	'vat_deductible': 'מוכר למע"מ',
	'vat_report': 'מע"מ',
	'verify_patient': 'אימות מטופל/ת',
	'version': 'גרסא',
	'versions': 'גרסאות',
	'medilogs': 'גרסאות',
	'view_all': 'הצגת כל הרשומות',
	'views': 'צפיות',
	'waiting_for_activation': 'ברגעים אלה נשלח לתיבת הדוא"ל שלך קוד ההפעלה.<br>    כל שעליך לעשות הוא להיכנס לתיבת הדוא"ל שלך ללחוץ על לינק ההפעלה ובכך לאשר שהרישום הוא שלך.<br>    מרגע זה המערכת פתוחה לשימוש מלא, בהצלחה!<br>        אנחנו מברכים אותך על הבחירה ב-my-cliniq כפלטפורמת ניהול הקליניקה שלך ונעשה את הכל כדי שהמערכת תפעל לשביעות רצונך המלאה.',
	'waiting_for_patient_activation': 'ברגעים אלה נשלח לתיבת הדוא"ל שלך קוד ההפעלה.<br>    כל שעליך לעשות הוא להיכנס לתיבת הדוא"ל שלך ללחוץ על לינק ההפעלה ובכך לאשר שהרישום הוא שלך.<br>    מרגע זה מערכת השיחות תהיה פתוחה עבורך לשימוש מלא, בהצלחה!<br>',
	'waiting_for_shop_to_contact_the_buyer': 'ממתין שהחנות תיצור קשר עם הקונה',
	'want_to_get_in_touch': 'אם רצונכם לשאול שאלה או לשלוח לי הודעה אנא מלאו את הפרטים למטה ואשתדל לחזור בהקדם האפשרי',
	'wants_to_reset_the_password': ' מבקש/ת לאפס את הסיסמא בשיחוח',
	'created_successfully': 'נוצר בהצלחה',
	'updated_successfully': 'עודכן בהצלחה',
	'we_are_sorry,_but_we_cant_find_the_userdetails': 'אנו מתנצלים, אך איננו מוצאים את פרטי המשתמש',
	'web_page': 'אתר',
	'web_site': 'אתר',
	'wednesday': 'רביעי',
	'wednsday': 'רביעי',
	'week': 'שבוע',
	'weekly': 'שבועי',
	'weight': 'משקל',
	'weight_in_grams': 'משקל בגרמים ( אלף גרם בקילו )',
	'welcome': 'ברוכים הבאים ל',
	'welcome_back': 'טוב שחזרת',
	'welcome_to': 'ברוכים הבאים ל',
	'whatsapp': 'ווטסאפ',
	'when': 'מתי',
	'which_softwares': 'אילו תוכנות?',
	'who_is_registere_with_the_email': ' ודוא"ל -  ',
	'whole_day': 'יום מלא',
	'whoops_no': 'אופס לא',
	'why': 'למה',
	'widowed': 'אלמן/נה',
	'with': 'עם',
	'with_how': 'עם מי',
	'without_personal_memorandum': 'ללא תרשומת אישית',
	'with_personal_memorandum': 'תרשומת אישית',
	'with_description': 'רשומה רפואית',
	'only_personal_memorandum': 'רק תרשומת אישית',
	'with_who': 'עם מי',
	'withholding_tax': 'ניכוי במקור',
	'is_withholding_tax': 'ניכוי במקור',
	'withholding_tax_percent': 'אחוז ניכוי במקור',
	'without': 'ללא',
	'withoutVat': 'כולל מע"מ',
	'without_type': 'ללא סיווג',
	'withoutvat': 'ללא מע"מ',
	'withvat': 'כולל מע"מ',
	'wrong_file_format': 'סוג קובץ שגוי',
	'wrong_file_dimensions': 'מימדי הקובץ שגויים, יש להקטין או להתייעץ עם התמיכה',
	'year': 'שנה',
	'year_of_birth': 'שנת לידה',
	'yes': 'כן',
	'yes_continu': 'כן, המשך',
	'you_are_here': 'הנך נמצא כאן',
	'you_are_invited': 'הזמנה לכניסה למערכת ההודעות',
	'you_are_invited_to_a_chat': 'הזמנה לכניסה למערכת ההודעות',
	'you_are_not_authorized': 'אינך מחובר/ת, אנא התחבר/י',
	'you_are_now_being_redirected': 'ההעברה למערכת מתבצעת',
	'you_can_cancel_this_approval': '** במידה ואינך מעונין/ת לקבל מסמכים בדוא"ל אנא ענו לדוא"ל הזה ואבטל את האישור.',
	'you_can_see_full_details_of_your_order_in_your_page': 'תוכל לראות פרטים מלאים על הזמנתך בדף האישי שלך',
	'you_cannot_edit_other_users_profiles': 'אינך יכול לערוך משתמשים אחרים',
	'you_cannot_edit_this_content': 'אינך ראשי לערוך תוכן זה',
	'you_dont_have_followups_for_today': 'אין לך פגישות היום',
	'you_have_a_new_message_waiting_for_you': 'הודעה חדשה ממתינה לך בתיבת ההודעות',
	'you_have_meeting': 'יש לך פגישה מחר',
	'you_have_overlaping_followupseries': 'יש חפיפה בפגישות',
	'you_have_overlaping_followupseries:': 'יש חפיפה בפגישות',
	'you_have_successfully_logged_out': 'יצאת מהמערכת בהצלחה',
	'you_have_successfully_unsubscribed': 'ביטלת בהצלחה את הרישום לרשימת התפוצה שלנו',
	'you_have_to_activate_your_account_before_you_can_login': 'עליך להפעיל את החשבון לפני הכניסה הראשונה למערכת',
	'you_must_check': 'יש לסמן בקליק (מימין לדוא"ל) את הדוא"ל שאליו רוצים לשלוח, אפשר כמה...',
	'you_must_renew_your_payment': 'עליך לחדש את התשלום שלך ולתת מחדש את פרט האשראי המלאים.',
	'you_registered_at': 'נרשמת בתאריך',
	'you_save': 'חסכת',
	'your_account': 'החשבון שלך',
	'your_account_was_activated_successfully': 'חשבונך אומת בהצלחה',
	'your_account_was_cancelled': 'חשבונך בוטל',
	'your_auto_renew_billing': 'כרטיס האשראי שלך חויב בסכום החודשי של המנוי של ב-My-Cliniq',
	'your_credit_card_has_expired': 'פג תוקפו של כרטיס האשראי שלך',
	'your_credit_card_no': 'כרטיס האשראי שלך המסתיים בספרות',
	'your_email': 'הדוא"ל שלך',
	'your_message_was_sent_and we_shall_contact_you_back_shortly': 'הודעתך נשלחה ואנו ניצור איתך קשר בהקדם',
	'your_monthly_payment': ' התשלום החודשי שלך הוא ',
	'your_outstanding_payment': 'בהוראת תשלום זו תחויב ב-',
	'you_paid': 'שילמת',
	'your_page': 'הדף שלך',
	'your_partner_is': 'השותף/ה שלך לשיחה',
	'your_password_has_been_reset_successfully': 'הסיסמה שונתה בהצלחה',
	'your_patient_contacts': 'אנשי הקשר של המטופלים',
	'your_patient_memos': 'תזכורות של מטופלים',
	'your_patient_statuses': 'סטטוסים של מטופלים',
	'your_subscription_is_free': 'השימוש בפלטפורמת My-Cliniq הינו ללא עלות לתקופה של חודש לצורך התתנסות.',
	'zip': 'מיקוד',
	'bold': 'מודגש',
	'italic': 'מוטה',
	'underline': 'קו תחתון',
	'removeformat': 'ללא עיצוב',
	'bgColor': 'צבע רקע',
	'rtl': 'ימין לשמאל',
	'ltr': 'שמאל לימין',
	'justify': 'יישור',
	'justifyRight': 'יישור לימין',
	'justifyCenter': 'מרכוז',
	'justifyLeft': 'יישור לשמאל',
	'Rtl': 'Rtl',
	'wrong_vacation_time': 'זמן החופשה שגוי, יש לשנות שעת סיום ',
	'jan': 'ינואר',
	'feb': 'פברואר',
	'mar': 'מרץ',
	'apr': 'אפריל',
	'may': 'מאי',
	'jun': 'יוני',
	'jul': 'יולי',
	'aug': 'אוגוסט',
	'sep': 'ספטמבר',
	'oct': 'אוקטובר',
	'nov': 'נובמבר',
	'dec': 'דצמבר',
	'sent': 'נשלח',
	'read': 'נקרא',
	'bounced': 'חזר',
	'Bounce': 'חזר',
	'bounce': 'חזר',
	'scheduled_reports': 'דוחות תקופתיים',
	'report_name': 'שם הדוח',
	'send_freq': 'תקופת הדוח',
	'date_in_month': 'יום בחודש',
	'in_month': 'בחודש',
	'3': '3',
	'5': '5',
	'7': '7',

	'attached_reports': 'רצ"ב הדוחות התקופתיים',
	'the_attached_reports_are': 'מצורפים הדוחות הבאים',

	'marked_holidays': 'החגים המסומנים יוגדרו כחופשה ביומן',
	'holiday_group': 'סוג חג',
	'national': 'לאומי',
	'jewish': 'יהודי',
	'muslim': 'מוסלמי',
	'christian_catholic_protestant': 'נוצרי- קתולי פרוטסטנטי',
	'christian_orthodox': 'נוצרי אורתודוכסי',
	'druze': 'דרוזי',
	"cherkess": "צ'רקסי",
	"you_seem_to_be_disconnected": "יש כנראה תקלה בחיבור לרשת. נא לבדוק שהחיבור תקין ואז לרענן את המסך ולהיכנס שנית",
	'total_invoice_total_without_vat': 'הכנסה לא כולל מעמ (סך עסקאות חייבות ופטורות)',
	'total_invoice_vat_required': 'עיסקאות חייבות (ללא מע"מ)',
	'total_invoice_vat': 'מע"מ על העסקאות',
	'total_expense_vat_permanent': 'מע"מ על ציוד ונכסים קבועים',
	'total_expense_vat_not_permanent': 'מע"מ על תשומות אחרות',
	'tax_for_payment': 'מס לתשלום',
	'tax_percent_downpayment': 'אחוז מיקדמות מס',
	'total_invoice_tax_downpayment': 'מיקדמה לפי המחזור העסקי',
	'there_is_credit': 'יתרה מתשלום נוסף בחשבונית קודמת',
	'there_is_debt': 'סכום חסר מתשלום קודם',
	'to_proceed_with_diff': 'קיים פער בין סך החוב לסכום שהוזן',
	'to_proceed': 'האם ברצונך להמשיך?',
	'new_advance': 'תשלום נוסף בחשבונית זו',
	'new_debt': 'חוב חדש',
	'please_include_the_followup_in_the_calculation': 'יש לכלול את כל הפגישות שנכללות בסכום החשבונית',
	'no_debt_on_invoice': 'אין להשאיר חוב בחשבונית',


	'border_color': 'צבע מסגרת',
	'template_id': 'תבנית',
	'lang': 'שפה',
	'you_were_invited': 'הוזמנת להירשם למערכת My-Cliniq ע"י',
  'you_were_invited_existing_partner': 'הוזמנת להיות בקשרי הפניה במערכת My-Cliniq ע"י',
	'you_have_to_register': 'בשלב זה עליך להירשם ע"י הכנסת הטלפון הנייד, שם המשתמש הרצוי לך, והסיסמא.',
	'add_new_role': 'הוספת תפקיד חדש',
	'confirm_duplicate_name': 'משתמש בשם זה קיים במערכת האם לקלוט?',
	'user_added': 'המשתמש נקלט',
	'role_added': 'התפקיד נקלט',
	'name_already_exists': 'השם קיים במערכת',
	'this_role_can_not_be_deleted': 'לא ניתן למחוק תפקיד זה, קיימים משתמשים המשוייכים אליו',
	'sub_user_register': 'הרשמת משתמש נוסף',
	'sub_users': 'ניהול משתמשים',
  'sub_users_count': 'משתמשים פעילים',
	'admin_sub_users': 'ניהול משתמשים',
	'sub_users_dashboard': 'ניהול משתמשים',
	'sub_users_therpaists': 'מטפלים',
	'filter_sub_users': 'סינון מטפלים',
	'clinic_roles': 'תפקידים במערכת',
	'perm': 'הרשאה',
	'perms': 'הרשאות',
	'perms_role': 'תפקיד',
	'role_perms': 'הרשאות לתפקיד',
	'user_perms': 'הרשאות למשתמש',
	'users_assoc': 'רשימת מטפלים למטופל',
	'assoc_users_for': 'רשימת מטפלים ל-',
	'is_assoc': 'מטפל ',
	'assoc_sub_user_id': 'מטפל ',
	'assoc': 'רשימת מטפלים',
	'assoc_inquiry': 'הפניה אל',
	'first_assoc': 'שיוך למטפל',
	'therapytype': 'סוג טיפול',
	'therapytypes': 'סוגי טיפול',
	'object_to_sub_users': 'מטפלים למטופלים/פגישות',
	'object_to_sub_users_title': 'מטפלים למטופלים',
	'application_link': 'קישור לאפליקצית התשלום',
	'bank_account_text': 'טקסט פרטי חשבון בנק',
	'pricingtype': 'סוג תמחור',
	'pricingtypes': 'סוגי תמחור',
	'price_minus_sub_user_tarif': 'לאחר ניכוי מטפל',
	'sub_user_tarif': 'מחיר למטפל',
	'sub_user_tarifs': 'מחירי מטפל',
  'sub_user_tarif_paid': 'שולם למטפל',
	'price_sub_user': 'מחיר מטפל',

	'print_email_prompt': 'בחירת הדפסה/דוא"ל למכתב',
	'the_system_was_reset': 'המערכת התרעננה לצרכי אבטחה, יש להתחבר מחדש',
	'unsubscribed_message': 'ברגע זה נפרדנו, נשמח להיפגש שוב',
	'free': 'חינם',
	'3months_free': 'בהתנסות 1 חודש',
	'print_multiple_invoices': 'שמירת המסמכים שנבחרו כקובץ PDF',
	'email_multiple_invoices': 'שליחת המסמכים שנבחרו בדוא"ל',
  'attached_summary': 'מצורף סיכום השעות לתשלום',
	'multiple_finresources': 'העתקי מסמכים',
	'mixed_list': 'תיק רפואי',
	'mixed_lists': 'תיק רפואי',
	'time_allocation': 'הקצאת זמן',
	'time_allocations': 'הקצאת זמן בטבלה',
  'time_room_allocation': 'הקצאת חדרים',
	'is_conflicting': 'התנגשות',
	'location': 'חדר',
	'location_id': 'חדר',
	'locations': 'חדרים',
	'no_location': 'ללא הקצאת מקום',
	'must_include': 'כותרת חשבונית חייבת להכיל  "עוסק מורשה" או ,"ע.מ." או  "עוסק פטור" או "ע.פ." או "ח.פ." או "ע.ר." + מספר ',
	'sub_user_group': 'קבוצת תפוצה',
	'sub_user_groups': 'קבוצת תפוצה',
	'sub_user_group_members': 'חברי הקבוצה',
	'sub_user_group_member': 'חבר הקבוצה',
	'create_potential_user': 'יצירת מתעניין/ת',
	'potential_user': 'מתעניין/ת',
	'potential_users': 'מתעניינות/ים',
	'for_an_answer': 'אין לענות להודעה זו. למענה יש לפנות בווטסאפ או בדוא"ל',
  'for_an_answer1': '** לפרטים נוספים יש לפנות ל',
  'for_an_answer2': 'באמצעות ווטסאפ בטלפון ',
  'for_an_answer3': 'או בדוא״ל ',
	'this_is_an_automated_message': 'זוהי הודעה אוטומטית',
	'you have a meeting': 'יש לך פגישה',
	'tomorrow': 'מחר',
	'at': 'בשעה',
	'error_in_time_range': 'טווח זמנים שגוי ',
	'time_is_taken': 'השעה תפוסה',
	'room_is_taken': 'החדר תפוס',
	'bkmvdata': 'ליצירת הקבצים -> הורדה וקבלת הדוח',
	'ini': 'ליצירת קובץ ini.txt',
	'sub_user': 'מטפל/ת',
	'all_fus': 'כל הפגישות',
	'non_patients': 'ללא מטופלים',
	'tasks': 'משימות ותזכורות',
	'task': 'משימה/תזכורת',

	'conflict_reason': '[in_to][conflict_reason_name] מתקיימת [a_meeting_or_series] [date], בשעה [time] עם [assocName] ([series_repeat]). ',
	'to1': 'ל',
	'a_one_time': 'פגישה בתאריך ',
	'a_series': 'סדרת פגישות שהחלה בתאריך ',
	'credit_invoice_preview': 'הצגת זיכוי חשבונית מס קבלה',
	'credit_justinvoice_preview': 'הצגת זיכוי חשבונית מס',
	'credit_receipt_preview': 'הצגת זיכוי קבלה',
	'credit_admininvoice_preview': 'הצגת זיכוי חשבונית',
	'credit_justinvoice': 'חשבונית זיכוי',
	'credit_receipt': 'חשבונית זיכוי',
	'credit_admininvoice': 'חשבונית זיכוי',
	'multiuser': 'הגדרות מכון',
	'no_group_assoc': 'לא ניתן לשייך קבוצה לפגישה טיפולית',
	'no_allocation': 'ללא הקצאת חדר',
	'max_1_group_assoc': 'ניתן לשייך קבוצה אחת לפגישה',
	'urgent': 'דחוף',
	'sms_notify': 'הודעה במסרון',
	'email_notify': 'הודעה בדוא"ל',
	'email_notify_with_message': 'לא סודי-דוא"ל מפורט',
	'email_notify_no_message': 'דוא"ל תזכורת',
	'set_is_uptodate': 'נצפה',
  'close_task': 'אישור ביצוע משימה',
	'open_close_chat': 'פתיחת.סגירת שיחה',
	'open_close_all_chats': 'פתיחת.סגירת כל השיחות',
	'add_comment': 'הוספת תגובה',
	'add_task': 'הוספת משימה.תזכורת',
	'calendar_addFusModal_finFields': 'הוספת שדות פיננסיים',
	'calendar_addFusModal_canOpen': 'עריכה והוספת פגישות יומן',
	'assign_sub_users': 'שיוך מטפלים',
  'receipts': 'הוצאת קבלות',
   'document_signing': 'חתימה על מסמך',
	'finances_expenses': 'הוצאות',
	'finances_income': 'הכנסות',
	'administrative': 'אדמיניסטרטיבי',
	'financial_documents': 'מסמכים חשבונאיים',
	'clinical_admin': 'ניהול קליני',
	'sees_admin_homepage': 'עמוד בית ניהולי',
	'exit': 'יציאה',
	'time_to_refresh': 'היומן התעדכן יש לרענן את המסך ',
	'inquirer': 'פונה',
	'addinquirer': 'הוספת פונה',
	'testquestion': 'שאלה',
	'testquestions': 'שאלות',
	'testanswer': 'תשובה',
	'answer': 'תשובה',
	'testanswers': 'תשובות',
	'answers': 'תשובות',
	'mark': 'ניקוד',
	'default_answer': 'תשובת ברירת מחדל',
    'clinical_home': 'עמוד קליני',
  'home-admin': 'עמוד הניהול',
  'add_insert_autotext': 'עריכת והוספת טקסטים',
  'choose_autotext': 'יש לבחור טקסט מובנה להוספה לרשומה',
  'choose_test': 'יש לבחור שאלון למילוי',
  'add_insert_tests': 'עריכת והוספת שאלונים',
  'tarif': 'תעריף למטפל.ת',
  'tarif_id': 'תעריף למטפל.ת',
  'tarifs': 'תעריפים למטפלים.ות',
  'to_update_delete': 'החלת העדכון',
  'to_delete': 'למחוק',
  'send_documents_to_patient': 'לשלוח חשבוניות מסומנות בדוא"ל',
  'downlaod_documents_to_patient': 'לשמור חשבוניות מסומנות',
  'calendar_view': 'תצוגת יומן',
  'list_view': 'רשימת הקצאות',
  'select_change_serie': 'נא לבחור את תחולת העדכון',
  'add_remarks': 'הערות למחיקה/ עדכון',
  'new_inquiry': 'פנייה חדשה',
  'new_inquiry_without': 'פנייה חדשה',
  'new_inquiry_with': 'פנייה חדשה עם הפנייה למטפל',
  'effective_date' :'התאריך שישתנה',
  'starting_from'  :'החל מתאריך',
  'vacation_all_users'  :'חופשה כללית',
  'time_allocation_warning'  :'התראה! השעה והמיקום מוקצים למטפל אחר',
  'not_in_allocation'  :'התראה! השעה חורגת מהקצאת המטפל',
  'referrer'  :'גורם מפנה',
  'authorized_signer'  :'מורשה חתימה מכון',
  'signer'  :'רשות לחתום',
  'authorized_to_sign'  :'מורשה חתימה אישי',
  'sub_users1'  :'א-נשים במכון',
  'sign'  :'אישור והוספת חתימה',
  'occupied'  :'החדר תפוס',
  'please_choose_date_column'  :'יש לבחור תאריך',
  'import_warning'  :'אם כבר התחלת לעבוד עם המערכת שלנו, ויש לך כבר מטופלים רשומים כדאי לשים לב שלא יווצרו מטופלים כפולים עקב בעיות איות, במקרה של תקלה, יש ליצור קשר עם התמיכה',
  'followups_sub_user_report'  :'מעקבים למטפל',
  'followups_sub_user_rent_report'  :'מעקבים וחדרים למטפל',
  'patients_sub_user_report'  :'מטופלים למטפל',
  'send_sms'  :'משלוח מסרוני תזכורת',
  'send_sms_cancel'  :'* לביטול משלוח המסרונים יש לפנות לאחראי המערכת',
  'sms_approved_cancel'  :'משלוח מסרונים מאושר ופועל. לביטול משלוח המסרונים יש לפנות לאחראי המערכת',
  'to_sms_terms'  :'לחתימה על הסכמה לתנאי השימוש',
  'to_sms_terms_description'  :'על מנת לשלוח מסרונים, יש לאשר את תנאי השימוש',
  'accept_sms_terms'  :'מסכימ.ה לתנאי השימוש',
  'serve_for_signing'  :'הגשה לחתימה',
  'needs_to_be_signed'  :'ממתין לחתימה',
  'sub_user_payments'  :'תשלומים למטפל',
  'unpermitted'  :'אין לך אישור למהלך הזה',
  'to_pay'  :'לתשלום',
  'to_pay_instructions'  :'לתשלום, יש להזין את הפרטים הבאים',
  'to_be_paid'  :'לתשלום',
  'to_get_paid'  :'לתשלום',
  'to_receive'  :'לקבלת תשלום',
  'the_sms_usage_is_approved'  :'לתשלום',
  'confirm_transfer'  :'אישור העברה',
  'confirm_invoice'  :'אישור קבלת חשבונית',
  'invoice_number'  :'חשבונית מטפל',
  'before_day'  :'לא ניתן לשנות סידרה רטרואקטיבית',
  'expense_sum'  :'סכום החשבונית',
  'expense_date'  :'תאריך החשבונית',
  'payment_for_month' :'תשלום עבור חודש',
  'payment_for' :'תשלום עבור',
  'amount_of' :'על סך',
  'transferred' :'הועבר לך',
  'month_in_future' :'החודש בעתיד, אין עדיין נתונים',
  'document-and-quest' :'תבניות מסמכים ושאלונים',
  'people'  :'מטופלים/אנשי קשר',
  'patients_sms' :'הרשאות תזכורת למטופלים',
  'sms_confirmed' :'השימוש במסרונים מאושר',
  'date_of_birth' :'תאריך לידה',
  'date_start_therapy' :'תאריך התחלת טיפול',

  'contact_name1': 'איש קשר 1 שם',
  'contact_id1': 'איש קשר 1 ת.ז.',
  'contact_relation1': 'איש קשר 1 קרבה',
  'contact_phone1': 'איש קשר 1 טלפון',
  'contact_email1': 'איש קשר  1  דוא"ל',
  'contact_name2': 'איש קשר 2 שם',
  'contact_id2': 'איש קשר 2 ת.ז.',
  'contact_relation2': 'איש קשר 2 קרבה',
  'contact_phone2': 'איש קשר 2 טלפון',
  'contact_email2': 'איש קשר 2 דוא"ל',
  'contact_name3': 'איש קשר 3 שם',
  'contact_id3': 'איש קשר 3 ת.ז.',
  'contact_relation3': 'איש קשר 3 קרבה',
  'contact_phone3': 'איש קשר 3 טלפון',
  'contact_email3': 'איש קשר 3 דוא"ל',
  'zones': 'הגדרת תיק מטופל',
  'apotropus': 'איש קשר/אפוטרופוס',
  'apotropuses': 'אנשי קשר/אפוטרופוס',
  'relation': 'קרבה',
  'no_letter_header_yet': 'למכון אין עדיין כותרת רישמית למכתבים',
  'assign_multi_user': 'הסבת משתמש מכון',
  'there_should be_change': 'יש להכניס את האחוז החדש שייכנס לתוקף מהתאריך שהוקש',
  'name_on_message': 'שם (באנגלית) בשליחת מסרון',
  'time_of_sms': 'זמן למשלוח מסרון',
  'days_to_sms': 'מס ימים לפני פגישה למשלוח תזכורת',
  'time_of_sms_description': 'המסרונים יישלחו יום לפני הפגישה בשעה 9 בערב או בבוקר',
  'morning': 'בבוקר',
  'evening': 'בערב',
  'wrong_text': 'טקסט שגוי',
  'name_should_contain': ' השם צריך להיות באורך 4-11 תווים(לכל היותר). באנגלית! רווח נקודה ומקף מותרים.',
  'your_balance_for_period': 'החשבון שלך הוא',
  'your_balance_at': 'החשבון שלך אצל',
  'send_sms_proforma': 'חשבון חודש קודם במסרון ',
  'send_sms_proforma1': 'קישור לתשלום במסרון',
  'link_will_be_sent': 'הקישור לתשלום יישלח למספר',
  'send_wa_proforma': 'חשבון חודש קודם בווטסאפ ',
  'send_email_proforma': 'חשבון חודש קודם בדוא"ל ',
  'confirm_sending': 'אישור שליחה',
  'resend_all_reports': 'שליחה חוזרת של כל הדוחות',
  'no_phone_or_no_permit': 'למטופל.ת/לקוח.ה אין טלפון  או אין הרשאה לקבלת מסרון',
  'row': 'שורה',
  'col': 'עמודה',
  'above': ' הוספה מעל',
  'below': 'מתחת',
 // 'remove': 'שליחה חוזרת של כל הדוחות',
  'before': 'הוספה מימין',
  'after': 'משמאל',
  'bg_color': 'צבע רקע',
  'group_name': 'שם הקבוצה',
  'faq' : 'שאלות נפוצות',
  'graphs': 'גראפים',
  'year_from': 'משנה',
  'year_to': 'עד שנה',
  'freq': 'תדירות',
  'quarterly': 'רבעוני',
  'yearly': 'שנתי',
  'income_price': 'עלות/הכנסה',
  'price_total': 'עלות פגישות',
  'columns': 'מקרא',
  'state': 'מצב',
  'states': 'מצבים',
  'revoke_in_days': 'להציג בעוד ימים',
  'repeat_every_days': 'להציג כל ימים',
  'meeting_was_deleted': 'יש לקלוט סיבת מחיקה של פגישת עבר',
  'hours': 'שעות',
  'meetings': 'פגישות',
  'new_patients': 'מטופלים חדשים',
  'inquirers': 'פונים חדשים',
  'patients_with_meeting': 'מטופלים עם פגישות',
  'show_all_open': 'הצגת משימות פתוחות',
  'show_only_today': 'הצגת משימות היום',
    'default_font_for_documents': 'פונט ברירת מחדל למכתבים',
    'miriamlibre': 'Miriam',
    'davidlibre': 'David',
    'frankruhllibre': 'Frankruehl',
  'your_one_time_password_is': 'הסיסמא החד פעמית שלך היא',
  'show_numbers_not_names': 'להראות מספרים במקום שמות',
  'show_emails': 'להראות דוא"לים',
  'show_expenses': 'תצוגת הוצאות',
  'show_by_created_at': 'לפי תאריך קליטה',
  'by_created_at': 'לפי תאריך קליטה',
  'by_expense_date': 'לפי תאריך הוצאה',
  'show_by_expense_date': 'לפי תאריך הוצאה',
  'show_only_followups': 'הצגת פגישות בלבד',
  'show_finresources_and_followups': 'הצגת פגישות וחשבוניות',
  'show_only_unpaid_followups': 'הצגת פגישות שלא שולמו',
  'no_permit_to_notify': 'אין הרשאה לשלוח הודעות למטופל הזה',
  'no_valid_phone': 'למטופל אין מס טלפון תקין',
  'no_followups_or_debt': 'אין למטופל מעקבים או חוב',
  'referers': 'גורמים מפנים',
  'referer': 'גורם מפנה',
  'refererpatients': 'גורמים מפנים',
  'refererpatient': 'גורם מפנה',
  'email_report_to_patient': 'דוח בדוא"ל למטופל',
  'resend_invite': 'לשלוח שוב הזמנה להירשם',
  'pdf-view': 'הצגה',
  'check_faq': 'במידה ויש לך שאלה, אנו ממליצים קודם לנסות לחפש אותה ברשימת השאלות הנפוצות להלן ',
  'patientfields': 'שדות נוספים לתיק המטופל',
  'patient_details_fields': 'בחירת שדות לטופס מטופל',
  'filter': 'סינון',
  'filter_locations': 'סינון מיקום',
  'phone_taken': 'הטלפון כבר תפוס',
  'inherited_value': 'ערך בהרשאות התפקיד',
  'add_referer': 'הוספת עוד גורם מפנה',
  'select_fields': 'בחירת שדות לתיק המטופל',
  'contactway_already_exists': 'אמצעי הקשר כבר קיים במערכת',
  'insert_patient_form': 'קליטת פונה חדש.ה',
  'reset_filters': 'אתחול סינון',
  'ref': 'אסמכתא',
  'birthday_today': 'היום יום ההולדת של',
  'was_added': 'נוספה פגישה',
  'updated_to': 'עודכנה פגישה',
  'was_deleted': 'נמחקה פגישה',
  'assoc_is': 'הפגישה קשורה...',
  'user_payments': 'תשלומי משתמש',
  'you_have_task_waiting': 'יש לך משימה/הפנייה ממתינה במערכת',
  'show_task_after_login': 'לצפייה במשימה לאחר ההתחברות למערכת',
  'payment_request': 'הסדרת תשלום',
  'insert_credit_card': 'נודה להזנת פרטי כרטיס האשראי לצורך הוראת הקבע. ',
  'payment_request_text_new': 'חודש ההתנסות שלך הסתיים. נודה להזנת פרטי כרטיס האשראי לצורך הוראת הקבע.',
  'payment_request_text_renew': 'ארעה תקלה בעת החיוב החודשי. כדי להבטיח את המשך השימוש התקין בתוכנה, יש לעדכן את פרטי האשראי.',
  'if_paid_ignore': 'במידה ונפלה טעות, ניתן ליצור עימנו קשר להסדרת הנושא. ',
  'inquiry_status': 'סטטוס הפניה ',
  'inquiry_statuses': 'סטטוס הפניה ',
  'unassigned_to_assoc': 'המטפל נותק מהמטופל',
  'assigned_to_assoc': 'המטפל קיבל הפנייה',
  'assign_to_assoc': 'הפנייה למטפל',
  'assign_recipient': 'מכותבים',
  'meeting_was_set': 'נקבעה פגישה',
  'contactway_search': 'חיפוש לפי טלפון או דוא"ל',
  'rent_report': 'דוח חדרים מושכרים',
  'locations_report': 'דוח חדרים תפוסים',
  'rent': 'חדרים תפוסים',
  'total_hours_logged': 'סך זמן במערכת',
  'average_time_logged': 'זמן שימוש ממוצע',
  'count_logs': 'כניסות',
  'dblogs': 'לוג-ישן',
  'faqs': 'שאלות נפוצות',
  'activity': 'לוג כניסות',
  'patient_status': 'סטטוס מטופל',
  'active_assoc': 'קשר פעיל',
  'reached_14': 'המטופל הגיע לפגישה ה-14',
  'delete_forbidden': 'אסור למחוק יש חשבונית',
  'supervisor_notes':'הערות מנחה',
  'choose_form': 'יש לבחור טופס למילוי',
  'add_insert_forms': 'עריכת והוספת טפסים',
  'edit_form': 'עריכת טופס',
  'form_name': 'שם טופס',
  'form_submit': 'קליטת טופס',
  'correct_answer': 'תשובת נכונה',
  'show_is_assoc': 'הצגת אפשרות ללא מטפל(לאיש קשר) ביומן',
  'with_assoc': 'עם מטפל',
  'without_assoc': 'ללא מטפל',
  'short_name': 'שם קצר',
  'for_patient': 'למילוי מטופל',
  'vvalue': 'תשובה',
  'value_not_in_range': 'ללא מטפל',
  'error_in_range': 'טווח שגוי',
  'add_option': 'הוספת תשובה אפשרית',
  'at_least_2': 'צריכות להיות לפחות שתי אפשרויות',
  'show_only_conflicting': 'להדפסה',

  'field_type' : 'סוג תשובה',
  'text' : 'טקסט',
  'opts-select' : 'שדה בחירה',
  'opts-checkbox' : 'תיבות סימון',
  'opts-btn-group' : 'כפתורים',
  'range_min' : 'טווח-ערך מינימום',
  'range_max' : 'טווח-ערך מקסימום',
    'options' : 'אפשרויות',
  'testanswers_fill' : 'תצוגת שאלון',
  'preview_filled_form' : 'תצוגה מקדימה של טופס',
  'backward' : 'הזזה לאחור',
  'forward' : 'הזזה קדימה',
  'position' : 'מיקום',
  'position_start' : 'ימין',
  'position_center' : 'מרכז',
  'position_end' : 'שמאל',
  'countable' : 'לספירה',
  'is_private' :'פרטי',
  'Sunday': 'ראשון',
  'Monday': 'שני',
  'Tuesday': 'שלישי',
  'Wednesday': 'רביעי',
  'Thursday': 'חמישי',
  'Friday': 'שישי',
  'Saturday': 'שבת',
  'irrelevant': 'לא רלוונטי',
  'row_can_have_either_1_or_2_items': 'בשורה יכולים להיות רק רכיב אחד או שנים',
  'if_row_has_2_they_can_only_be_right_and_left': 'אם יש שני רכיבים בשורה הם צריכים להיות בימין ובשמאל',
  'if_center_only_one_item': 'במרכז השורה יכול להיות רק רכיב אחד',
  'show-item': 'הצגה',
  'get_2fa_key': 'התחברות לאפליקציה לאימות 2FA',
  'the_key': 'המפתח לאימות 2FA באמצעות אפליקציה',
  'is_google2fa': 'שימוש באימות 2FA באמצעות אפליקציה',
  'headerText': 'כותרת מסמך',
  'design_your_document': 'עיצוב עצמי',
  'area': 'איזור בדף',
  'count_is_private_yes': 'סה"כ פרטיים',
  'count_is_private_no': 'סה"כ לא פרטיים',
  'not_found': 'לא נמצא',
  'choose_or_insert': 'יש לבחור מטופל/איש קשר מהרשימה, או להוסיף חדש באמצעות לחיצה על הפלוס משמאל',
  'confident': 'סודי(טקסט גנרי)',
  'name_is_saved': 'השם שמור במערכת',
  'create_takbull_account': 'יצירת חשבון סליקה במערכת תקבול',
  'ita_get_auth': 'קבלת אישור מרשות המסים לביצוע פעולות',
  'ita_refresh_token': 'רענון מפתחות הגישה לרשות המסים',
  'enable_israel_invoice': 'שימוש בחשבוניות ישראל',
  'affiliate_link': 'העתקת קישור להרשמת חברים',
  'affiliate_header': 'מבצע חבר.ה מביא.ה חבר.ה ב My-Cliniq',
  'affiliated_users': 'משתמשים שהפניתי',
  'to_meeting_request': 'לתיאום פגישה',
  'to_request_meeting_request': 'בקשה לתיאום פגישה',
  'meeting_request': 'תיאום פגישה',
  'add_meeting_request': 'הוספת בקשה לתיאום פגישה',
  'meeting_requests': 'בקשות לתיאום פגישה',
  'select_partner': 'בחירת עמית',
  'already_in_partnership': 'עמית קיים במערכת',
  'show_motions': 'מעקב הפניות',
  'meeting_request_with_no_calendar': 'בקשה לתיאום פגישה ללא יומן',
  'meeting_request_with_calendar': 'הפעלת תיאום תורים עצמי',
  'meeting_request_was_sent': 'פנייתך התקבלה והועברה ל',
  'meeting_details': 'ביקשת להיפגש ביום ',
  'your_meeting_details': 'בקשתך להיפגש ביום ',
  'you_have_a_meeting_request_on': 'יש לך בקשה לפגישה ב-',
  'you_have_a_contact_request': 'יש לך בקשה ליצירת קשר',
  'the_contact_request_will_appear': 'הבקשה תופיע ברשימת המשימות שלך.',
  'external_link': 'קישור לתיאום עצמי',
  'copy_external_link': 'לעמוד התיאום העצמי',
  'copy_external_calendar_link': 'לעמוד היומן ללא התחברות',
  'copy_link': 'העתקת הקישור',
  'copy': 'העתקת ',
  'copycopy': 'העתק',
  'phone_search': 'חיפוש לפי טלפון',
  'confirm_meeting': 'אישור פגישה',
  'isNew': 'האם חדש?',
  'individual': 'פרטני',
  'this_is_group': 'השם שנבחר הוא קבוצה - לא ניתן למזג קבוצות',

  'same_patient': 'זה מטופל זהה',
  'wellcome': 'ברוכים הבאים למערכת My-Cliniq',
  'i_want_instruction': 'אני רוצה הדרכה',
  'received_instruction_request': 'בקשת ההדרכה שלך התקבלה בצלחה, המדריך.ה ייצרו איתכם קשר בהקדם.',
  'do_you_want_instruction': 'כדי שתוכלו להנות ולנצל את כל יכולות המערכת, אנו מציעים פגישת הדרכה <b>חינם וללא כל התחייבות </b>כך שתתאים בדיוק למה שאתם צריכים.<br>נשמע טוב? מוזמנים ללחוץ כאן ונחזור אליכם',
  'do_you_want_instruction1': 'כדי שתוכלו להנות ולנצל את כל יכולות המערכת, סידרנו לכם שיחת הדרכה<b> חינם וללא כל התחייבות</b> כך שתתאים בדיוק למה שאתם צריכים.<br>נשמע טוב? לחצו כאן ובואו נדבר',
  'do_you_want_instructionO': 'מומלץ לקחת הדרכה על התוכנה (חינם ללא התחייבות).<br>לבקשת הדרכה אנא הקליקו על הכפתור למטה וניצור איתכם קשר לתיאום.',
  'do_you_want_instruction_multi': 'הקמת סביבת עבודה מיטיבה יכולה להיות תהליך מורכב. <br>למרבה המזל, אנחנו פה כדי לתמוך.<br>' +
    'מומלץ לקבוע פגישת הדרכה טלפונית לצורך ההקמה הראשונית והכרת תכונות המערכת.<br>\n' +
    'לבקשת הדרכה, כל שצריך לעשות הוא ללחוץ על הכפתור.\n',
  'instruction_suggest': 'הצעת הדרכה',
  'patient_source': 'המטופל שממנו יעברו הנתונים ויימחק',
  'patient_target': 'המטופל שיישאר במערכת',
  'unify': 'איחוד',
  'patient_unification': 'איחוד מטופלים כפולים',
  'message_sent_to_instructor': 'ההודעה נשלחה למדריך',
  'bn_number': 'ח.פ.',
  'first_time_meeting_request': 'הזמנה ברשת-פונה חדש',
  'return_meeting_request': 'הזמנה ברשת-מטופל חוזר',
  'name_exists_differnet_phone': 'שם מטופל קיים במערכת הטלפון שונה',
  'name_exists_phone_belongs_to_another_name': 'שם קיים במערכת - הטלפון קיים לשם אחר',
  'name_does_not_exist_phone_exists': 'שם לא קיים במערכת - הטלפון קיים',
  'switch_locations': 'החלפת חדרים',
  'no-locations_at_this_time': 'אין חדרים להחלפה בזמן המדוייק הזה',
  'open_test_manage': 'ניהול שאלונים',
  'all_answers_filled_saved': 'התשובות לשאלון נשמרו',
  '2FA0': 'לטובת מי שאינו מקבל הודעות טקסט, יש לו קשיים בקבלת מסרונים או שוהה בחול, נוספה האפשרות לעשות אימות נוסף בעזרה אפליקציה',
  '2FA1': 'כדי לעבור לאימות דו שלבי בעזרת אפליקציה במקום סמס (טקסט או קולי)',
  '2FA2': '1. יש להוריד אפליקצית אימות לנייד (המוכרת לנו היא  authenticator  של גוגל. יש אחרות)',
  '2FA3': '2. לפתוח את האפליקציה, להתחיל חיבור חדש (בד"כ בהקשה על + באפליקציה) לקרוא לו My cliniq',
  '2FA4': 'לבקשת האפליקציה לסרוק את תמונת ה-QR code, יש לפתוח את התפריט האישי (למעלה משמאל) לבחור: מפתח לאימות 2FA באמצעות אפליקציה ושם תופיע התמונה.\n',
  '2FA5': '4. לשמור באפליקציה.',
  '2FA6': 'מרגע זה בכל פעם שתידרשו לתת קוד אימות, תפתחו את האפליקציה, והמספר שיופיע שם הוא קוד האימות שלכם.',
  '2FA7': 'יש לסמן את מתג השימוש באפליקציה  ב"כן" ומרגע זה המהלך הזה יחליף  את השימוש ב-SMS',
  'for_first_time_meeting': 'לבחירת מועד פגישה ראשונה',
  'for_return_meeting': 'לבחירת מועד פגישה חוזרת',
  'deactivate_meeting_request': 'סירוב פנייה',
  'tests_manage': 'ניהול שאלונים',
  'close_task_with_meeting_request': 'אזהרה! סגירת המשימה תסגור גם את הבקשה לפגישה',
  'your_meeting_request_for': 'בקשתך לקיום פגישה עם',
  'to_meeting-request_no_calendar': 'לפנייה ללא תיאום תור',
  'to_meeting-request_no_calendar_with': 'לפנייה ללא תור',
  'confirmed': 'אושרה',
  'denied': 'נדחתה',
  'your_request_was_sent': 'בקשתך לפגישה נשלחה  ותיענה בהקדם',
  'to_payment_patient': 'לתשלום החשבון',
  'with_credit_card': 'בכרטיס אשראי',
  'with_bit': 'בביט',
  'meetingtypes_and_time_allocation_shoud_be_defined': 'כדי לקבל קישור, יש להגדיר הקצאות זמן וסוגי פגישה מתאימים להזמנה מקוונת',
  'thank_you_for_your_referral': 'תודה על ההפניה של',
  'your_next_payment_is_free': 'חודש השימוש הבא, יהיה ללא תשלום.',
  'thank_you_from_my_cliniq': 'My Cliniq-תודה מ',

  'returning': 'חוזר.ת',
  'time_is_requested': 'השעה הזו הוזמנה',
  'already_has_alocation': 'קיימת כבר הקצאת זמן ליום ולשעות האלה',
  'the_request_will_appear': 'הבקשה תופיע ביומן או ברשימת המשימות שלך.',
  'select_meeting_type': 'יש לבחור את סוג הפגישה הרצוי',
  'choose_one': 'מסך בחירה',
  'choose_one_email': 'לבחירת דוא"ל',
  'choose_one_phone': 'לבחירת טלפון',
  'choose_one_phone_and_one_email': 'יש לבחור דוא"ל אחד וטלפון אחד',
  'email_is_missing': 'אין דוא"ל למשלוח ההזמנה',
  'phone_is_missing': 'אין טלפון למשלוח ההזמנה',
  'invitation_sent_successfully': 'ההזמנה נשלחה בהצלחה',
  'invitation_not_sent': 'שליחת ההזמנה נכשלה',

  'too_many_tries': 'יותר מידי ניסיונות',
  'wrong_otp': 'קןד שגוי',
  'login_failed': 'לוגין נכשל',
  'register_failed': 'ההרשמה נכשלה',
  'resend_otp': 'לא קיבלתי את הקוד, נא לשלוח שוב',
  'send_invite' : 'למילוי המטופל',
  'send_invite_to_fill' : 'הזמנת מטופל למלא פרטים',
  'send_patient_form' :'הוספה + הזמנה למלא פרטים',
  'to_patient_fill_form' :'למלא את הפרטים שלך',
  'patient_fill_form' :'למילוי הפרטים',
  'update_from_patient_form' :'עדכון פרטים מטופס מטופל',
  'updated_from_patient_form' :'פרטי מטופל עודכנו',
  'choice' : 'בחירה',
  'selected_email' : 'הדוא"ל שנבחר',
  'selected_phone' : 'הטלפון שנבחר',
  'sent_invite' : 'הזמנת ההתחברות נשלחת עכשיו שוב',
  'invitation_cancelled': 'שליחת ההזמנה בוטלה',
  'occupation' : 'עיסוק',
  'full_address': 'כתובת מלאה',
  'languages': 'שפות',
  'areas_of_expertise': 'התמחות',
  'website_link': 'אתר',
  'an_invitation_to_register_to_my-cliniq' :'הזמנה להשתמש בתוכנת my-cliniq' ,
  'you_are_invited_by' :'הוזמנת ע"י ' ,
  'to_register_to_my_cliniq':' למערכת my-cliniq',
  'after_registration_you_will_be_able_to_access_your_payments':'השימוש במערכת יאפשר לך לצפות בהיסטוריית התשלומים שלך, ',
  'see_when_is_your_next_meeting':'לראות מתי פגישתך הבאה ',
  'and_more':'ועוד...',
  'please_click_on_the_link': 'לכניסה למערכת יש להקליק על הלינק ',
  'to_pay_please_click_on_the_link': 'לתשלום יש להקליק על הלינק ',
  'to_pay_by_credit': 'לתשלום באשראי ',
  'with_payment-details':'הצגת פירוט פגישות במסמך',
  'without_payment-details':'לא',
  'search_by_age':'חיפוש לפי גיל',
  'digits_only':'יש למלא ספרות בלבד',
  'test_association':'שיוך לשאלון על',
  'duplaicate-test':'שכפול שאלון',
  'patient_sign_required':'נדרשת חתימת מטופל',
  'required':'תשובה נדרשת',
  'user_sign_required':'נדרשת חתימת מטפל',
  'your_code_is':'המספר המזהה שלך הוא',
  'invitation_to_login':'My-Cliniq הזמנה לכניסה למערכת ',
  'message_is_waiting':'ממתינה לך הודעה בעמוד האישי שלך אצל ',
  'in_my_cliniq':'במערכת My-Cliniq  ',
  'to_enter_click_here':'לכניסה להודעה יש להקליק כאן  ',
  'patient_is_registered':'המטופל כבר רשום, בהקשה על "שליחה" תישלח הזמנה לכניסה למערכת',
  'patient_should_register':'המטופל כבר רשום, בהקשה על "שליחה" תישלח הזמנה חוזרת להירשם',
  'phone_and_email_are_required':'יש לבחור דוא"ל וטלפון כדי להזמין מטופל להתחבר',
  'phone_or_email_are_required':'יש לבחור דוא"ל  או טלפון כדי לשלוח את הטופס',
  'extra_freeform_details':'נושאים נוספים',
  'contactway_list':'רשימת דוא"לים ליצוא',
  'test_sent':'הזמנה למילוי שאלון נשלחה',
  'test_invite':'הזמנה למילוי שאלון',
  'to_the_test':'למילוי שאלון',
  'to_fill_in_test':'למלא שאלון/טופס',
  'filled_test_sent':'השאלון נשלח בהצלחה',
  'group_vacation_forbidden':'אין לקלוט חופשה לקבוצת מטפלים',
  'waiting_tests':'שאלונים/טפסים הממתינים למילוי',
  'please_fill':'יש למלא את הטופס להלן',
  'please_fill_in_the_link':'יש למלא את השאלון בקישור הבא:',
  'please_fill_in_the_links':'יש למלא את השאלונים בקישורים הבאים:',
  'insert_future_meetings':'הוספת פגישות עתידיות',
  'without_invoice':'ללא חשבונית מס',
  'test_should_be_signed':'יש לחתום על השאלון',
  'patient_signature':'חתימת לקוח',
  'user_signature':'חתימת עד',
  'to_set_the_appointment':'לפנייה',
  'to_send_the_request':'לשליחת הבקשה',
  'confirmation_sent':'הודעת אישור נשלחה במסרון לטלפון שהוזן',
  'see_you_on':'נתראה ב',
  'accepted':'התקבלה',
  'accept':'אישור',
  'all_vat_numbers':'כל מספרי המע"מ',
  'to_download':'האם להוריד ולשמור את השאלון למכשירך?',
  'no_meetingtypes_available':'אין סוגי פגישות מתאימות לבקשה הזו',
  'is_selfinvoice':'אישור להפקת חשבונית עצמית',
  'selfinvoice':'חשבונית עצמית',
  'first_selfinvoice_num':'מספר חשבונית עצמית התחלתי',
  'name_should_be_inserted':'יש להזין שם',
  'phone_should_be_inserted':'יש להזין טלפון',
  'email_should_be_inserted':'יש להזין אימייל',
  'the_followup_has_description':'לפגישה שעומדת להימחק יש תיעוד. האם למחוק?',
  'is_finresource_connected_only':'חשבונות עסקה פתוחים',
  'open_proformas':'חשבונות עסקה פתוחים',
  'record_is_locked':'התיק נעול לעדכונים.  נערך על ידי משתמש אחר בדקות אלה.',
  'partner':'עמית',
  'partners':'עמיתים',
  'invite_partner':'הזמנת עמית',
  'please_enter_partner_email': ' יש להזין כאן את כתובת הדוא"ל של העמית',
  'declined': 'נדחתה',
  'directed': 'הופנתה',
  'show_letters': 'הצג מכתבים',
  'show_documents': 'הצג מסמכים ב-Pdf',
  'show_therapist': 'הצגת שם המטפל בפירוט החשבונית',
  'show_email_in_pdf': 'הצגת כתובת דוא"ל ב- Pdf',
  'ok' : 'תקין',
  'validating' : 'בבדיקה אם קיים',
  'taken': 'המספר/דוא"ל קיים במערכת',
  'former_debt': 'חוב קודם',
  'add_former_debt': 'הוספת חוב קודם',
  'former_debt_text': 'ניתן להוסיף באופן חד-פעמי, סכום חוב קודם, עבור פגישות שהתקיימו לפני תחילת השימוש',
  'the_request_will_be_verified': 'הבקשה תיבדק ואישור סופי יישלח אליך.',
  'preferences': 'העדפות',
  'signature_logo': 'תמונת חתימה',
  'meeting_times': 'הצעת מועדי פגישות',
  'checking' : 'בבדיקה',
  'set_meeting' : 'לתיאום',
  'accept_patient' : 'קבלת מטופל',
  'accept_patient_terms' : 'אני מאשר.ת את התנאים לקבלת המטופל',
  'make_not_active' : 'להפוך ללא פעיל',
  'no_saturday' : 'להעלים שבת מהיומן',
  'enable_past_followups_on_followup' : 'הצגת מפגשים קודמים בעמוד המעקב',
  'enable_group_attends_on_followup' : 'הצגת רשומות רפואיות של משתתפי קבוצה בעמוד המעקב',
  'enable_less_fus' : 'יומן רק שנה אחורה',
  'id_number_required' : 'למטופל צריך להיות מספר ת.ז.',
  'finResource_person_list' : 'רשימת מסמכים חשבונאיים',
  'computerized_document' : 'מסמך ממוחשב',
  'future_meetings' : 'פגישות עתידיות',
  'repayment_by_token' : 'תשלום חוזר',
  'MaxNumberOfPayments' : 'מספר תשלומים מקסימלי לתשלומי אשראי',
  'affiliate_name' : 'מי הזמין אותי?',
  'affiliate_text1' : 'חברה מביאה חברה',
  'affiliate_text2' : 'הוזמנת על ידי קולגה להירשם למערכת My-Cliniq לניהול קליניקה. לאחר ההרשמה, תקבלי גישה למעטפת הכוללת תיקי מטופלים, יומן פגישות, חשבוניות ועוד. ללא התחייבות וללא הזנת פרטי אשראי מראש.',
  'affiliate_text3' : 'כיוון שהוזמנת, אנחנו מעניקים לך חודש ניסיון נוסף על חשבון הבית - סך הכל שני חודשי ניסיון',
  'affiliate_text4' : 'לאחר תקופת הניסיון, עלות השימוש החודשית במערכת הוא 79 ש"ח + מע"מ',
  'affiliate_text5' : 'אין הגבלה על מספר המוזמנים (למעט במקרים של שימוש לרעה)',
  'affiliate_text5a' : 'ההנחה ניתנת במעמד ההרשמה.',
  'affiliate_text6' : 'מתמחים בפסיכולוגיה? לאחר ההרשמה - שילחו לנו את תחום ההתמחות, המוסד בו היא מתקיימת ותאריך סיום משוער - ותהנו מכל מאפייני המערכת - ללא תשלום - עד תום ההתמחות',
  'link_text1' : 'הרשמה להתנסות',
  'link_text2' : 'באפשרותך להירשם לחודש ניסיון בחינם, ללא התחייבות וללא הזנת פרטי אשראי.',
  'link_text3' : 'מתמחים בפסיכולוגיה? לאחר ההרשמה - שילחו לנו את תחום ההתמחות, המוסד בו היא מתקיימת ותאריך סיום משוער - ותהנו מכל מאפייני המערכת - ללא תשלום - עד תום ההתמחות',
  'link_text4' : ' ',
  'link_text5' : 'לאחר תקופת הניסיון, עלות השימוש החודשית במערכת הוא 79 ש"ח + מע"מ',
  'assoc_date' : 'תאריך שיוך',
  'first_meeting_date': 'תאריך פגישה ראשונה',
  'last_meeting_date': 'תאריך פגישה אחרונה',
  'active_users': 'משתמשים פעילים',
  'active_patients': 'מטופלים פעילים',
  'date_format': 'תבנית תאריך',
  'invalid_date': 'תאריך לא תקין',
  'recommended' : 'מומלץ',
  'security' : 'אבטחה',
  'self_coordination': 'תיאום עצמי',
  'maximize': 'הרחבה',
  'minimize': 'צמצום',
  'show_future_meetings' : 'הצגת פגישות עתידיות בתיק הרפואי',
  'show_search_by_phone' : 'הצגת חיפוש לפי טלפון',
  'test_id' : 'שאלון',
  'sub_test_id' : 'תת שאלון',
  'not_allowed' : 'אינך מורשה למהלך הזה- המהלך מורכב ויש לבצע אותו בתיאום עם התמיכה הטכנית',
  'decline_instructions' : 'לא להראות שוב',
  'leave_affiliate_name_empty' : 'ניתן להשאיר ריק ולהירשם לחודש התנסות אחד',
  'contacts_meetings' : 'דוח פגישות לא טיפוליות',
  'count' : 'סה"כ',
  'expenses_by_created_at_default' : 'הוצאות לפי תאריך קליטה כברירת מחדל',
  'expenses_by_former_month' : 'הוצאות מחושבות לחודש קודם',
  'debts_payor_report': 'חובות גורם מממן',
  'install_app': '» התקנת אפליקציה',
  'taxesReportMode': 'אופן יצירת דוח מסים',
  'taxes_report_mode': 'אופן יצירת דוח מסים',
  'taxes_income_tax': 'דוח מקדמות',
  'taxes_vat': 'דוח מע"מ',
  'confirm_partnership': 'אישור הצטרפות כעמית',
  'i_confirm_partnership': 'הריני לאשר  את תנאי שיתוף הפעולה',
  'report_selection': 'בחירת דוחות',
  'select_a_patient': 'יש לבחור מטופל מהרשימה',
  'until_last_month': 'נכון לחודש קודם',
  'until_now': 'נכון להיום',
  'add_payor_to_followup': 'גורם מממן',
  'multi_user': 'מרובה משתמשים',
  'subscription_type': 'סוג הרשמה',
  'entry_selection': 'בחירת כניסה',
  'list_of_owners': 'אפשרויות כניסה למערכת אחרת',
  'proceed_with_main_uesr': 'להמשיך כמשתמש ראשי',
  'mirror_user_please_login_as': 'יש לך הודעה במערכת, יש להתחבר כמשתמש של',
  'remembered': '» נזכרת?',
   'is_locked': 'שאלון נעול',
   'email_proforma': 'חשבון באימייל',
   'email_invoice': 'חשבונית באימייל',
   'send_proforma': 'משלוח חשבון',
   'please_pay-ahead': 'יש לשלם מראש',
   'no_report_was_chosen': 'לא נבחר אף דוח',
   'budget_sum': 'תקציב',
   'budget_for_payors': 'תקציב לגורם מממן',
   'budget_percent_for_alert': 'אחוז מהתקציב להתראה',
   'budget_date': 'תאריך תקציב',
   'budget_usage': 'ניצול התקציב',
   'budget_usage_percent': 'אחוז ניצול התקציב',
  'payor_budget_alert_message1': 'התקציב של ',
  'payor_budget_alert_message2': 'עבר את אחוז ההתראה שלו ',
  'payor_budget_alert': 'התראת ניצול תקציב',
   'account_for_deposit': 'חשבון להפקדה',
  'last_365_days': 'שנה אחרונה',
  'last_30_days': '30 ימים',
   'last_90_days': '3 חודשים אחרונים',
   'has_closing_tags': 'תווים לא מורשים',
   'flip': 'שיוך לשאלון',
   'name_on_invoice': 'שם בחשבונית',
    'search_by_tags_add_meeting': 'חיפוש ע"פ תגיות בהוספת פגישה',
    'select_report_fields': 'בחירת עמודות להצגה',
    'personalize': 'התאמה אישית',
    'invoices_report_by_opts': 'אפשרויות הדוח',
    'report_defs': 'הגדרת דוח',
    'tests_in_group': 'שאלונים בחבילה',
    'testgroups': 'חבילות שאלונים',
    'add_to_group': 'חבילות שאלונים',
  'invalid_text': 'טקסט לא תקין- מומלץ לפנות לתמיכה לבירור',
  'version_change': 'הגירסה השתנתה, יש לרענן ולנסות שוב. במידה והתקלה חוזרת אנא הודיעו לתמיכה',
  'guest_sched_hours_to_add': 'מרווח הזמן להצעת השעות לתיאום עצמי',
    'patient_id_number': 'מס ת.ז.',
    'contact_id': 'איש קשר',
    'expensetype_id': 'סוג הוצאה',
    'blank_value': ' ',
    'after_today': 'תאריך סיום לא יכול להיות עתידי',
    'token_received': 'הגישה אושרה',
    'token_was_not_received': 'הגישה לא אושרה',
    'wa_app_installed': 'אפליקציית ווטסאפ מותקנת במחשב',
    'ita_no_signup': 'קבלת אישור לביצוע פעולות למשתמש רשום ברשות המסים',
    'ita_no_login': 'בקשת להצטרף לחשבוניות ישראל אך טרם ביצעת כניסה דרך המערכת לקבלת מפתחות הגישה',
    'ita_refresh': 'יש להקליק על הלינק לקבלת מפתחות גישה לרשות המסים',
    'ita_sign_up': 'להצטרפות לדיגיטל של רשות המסים',
    'shekel': 'שקל',
    'get-israel_invoice': 'קבלת מספר הקצאה לחשבונית',
    'israel_invoice': 'מספר הקצאה',
    'automation': 'אוטומציה',
    'intern': 'מתמחה',
    'download_documents': 'שמירת מסמכים במחשב',
  'COATED TABLETS': 'כדורים',
  'COATED TABLETS1': 'כדור',
  'FILM COATED TABLETS': 'כדורים',
  'FILM COATED TABLETS1': 'כדור',
  'MODIFIED RELEASE CAPSULES':'קפסולות',
  'MODIFIED RELEASE CAPSULES1':'קפסולה',
  'CAPSULES':'קפסולות',
  'CAPSULES1':'קפסולה',
  'OROMUCOSAL SOLUTION':'תמיסה',
  'SOLUTION':'תמיסה',
  'SOLUTION (ORAL)':'תמיסה לשתיה',
  'SOLUTION FOR INJECTION':'תמיסה להזרקה',
  'SOLUTION FOR INJECTION / INFUSION':'תמיסה להזרקה/ עירוי',
  'SYRUP':'סירופ',
  'OROMUCOSAL SOLUTION1':'תמיסה',
  'SOLUTION1':'תמיסה',
  'SOLUTION (ORAL)1':'תמיסה לשתיה',
  'SOLUTION FOR INJECTION1':'תמיסה להזרקה',
  'SOLUTION FOR INJECTION / INFUSION1':'תמיסה להזרקה/ עירוי',
  'SYRUP1':'סירופ',
  'TABLETS':  'כדורים',
  'TABLETS1':  'כדור',
  'TABLETS CONTROLLED RELEASE': 'כדורים',
  'TABLETS CONTROLLED RELEASE1': 'כדור',
  'TABLETS EXTENDED RELEASE': 'כדורים',
  'TABLETS EXTENDED RELEASE1': 'כדור',
  'TABLETS PROLONGED RELEASE': 'כדורים',
  'TABLETS PROLONGED RELEASE1': 'כדור',
  'times': 'פעמים',
  'time_1': 'פעם אחת',
  'patient_in_my_clinic': 'בטיפול במרפאתי',
  'diagnosed_with': 'מאובחן ב',
  'pagination_in_big_tables': 'חלוקה לדפים בטבלאות גדולות',
  'MG': 'מ"ג',
  'MG/ML': 'מ"ג/מ"ל',
  'adhd_note': 'באבחנה של ADHD - הפרעת קשב וריכוז',
  'treated_with': 'בטיפול ב',
  'parents_married': 'נשואים',
  'parents_not_married': 'לא נשואים',
  'father_widowed': 'אלמן',
  'mother_widowed': 'אלמנה',
  'parents_divorced': 'גרושים',
  'invoice_id_patient': 'חשבונית מטופל',
  'invoice_id_payor': 'חשבונית מממן',
  'copy_guest_payment_link': 'העתקת קישור לעמוד תשלום',
  'short_meetings_calendar': 'יומן לפגישות קצרות',
  'notifications_upon_login': 'פתיחת חלון משימות עם הכניסה למערכת',
  'public_tests': 'שאלונים ציבוריים',
  'products': 'מוצרים',
  'product': 'מוצר',
  'use_this': 'שימוש בשאלון',
  'sub_title': 'כותרת משנה',
  'external_id': 'זיהוי חיצוני',
  'product_person': 'רוכש',
  'product_persons': 'רוכשים',
  'select_tag': 'בחירת תגית',
  'default_medical_letter': 'תבנית למכתב רפואי',
  'got_instruction': 'קיבלו הדרכה',
  'instractor_name': 'שם המדריך',
  'coupon': 'קוד קופון',
  'coupon_price': 'סכום קופון',
  'coupon_date_limit': 'תאריך תוקף',
  'no_hotmail': 'לא ניתן להירשם עם כתובת הוטמייל. יש לבחור כתובת דוא"ל אחרת',

  'walkthrough': 'הדרכה מקוונת',
  'next_step': 'להמשך',
  'next_time': 'תודה, לא עכשיו',
  'see_u_next_time': 'חבל שעזבת...',
  'bye': 'להתראות',
  'walkthrough_step_o_line_1': 'ברוך בואך',
  'walkthrough_step_o_line_2': 'לרשותך מערכת היכרות ראשונית והתחלת עבודה מהירה',
  'walkthrough_step_o_line_3': 'aaaaaa',
  'walkthrough_step_o_line_4': 'aaaaaa',
  'walkthrough_step_o_line_5': 'aaaaaa',
'walkthrough_step_1_line_1': 'מה חשוב לך?',
  'walkthrough_step_1_line_2': 'התחלת עבודה מהירה',
  'walkthrough_step_1_line_3': 'היכרות עם המערכת',
  'walkthrough_step_1_line_4': 'תמיכה',
  'walkthrough_step_1_line_5': 'aaaaaa',
  'walkthrough_step_2_line_1': 'התחלת עבודה מהירה',
  'walkthrough_step_2_line_2': 'עלי',
  'walkthrough_step_2_line_3': 'הגדרות עבודה בסיסיות (פגישות/יומן - חשבוניות - העדפות בסיסיות)',
  'walkthrough_step_2_line_4': 'נתונים ראשוניים(מטופלים/יומן) [אפשרות קליטה בטבלה?, קבוצות?)',
  'walkthrough_step_2_line_5': 'aaaaaa',
  'walkthrough_step_3_line_1': 'מ333333',
  'walkthrough_step_3_line_2': '333333',
  'walkthrough_step_3_line_3': '3333',
  'walkthrough_step_3_line_4': '3333333333',
  'walkthrough_step_3_line_5': '3333',
'walkthrough_step_4_line_1': '444444',
  'walkthrough_step_4_line_2': '444444',
  'walkthrough_step_4_line_3': '444444',
  'walkthrough_step_4_line_4': '444444',
  'walkthrough_step_4_line_5': '444444',
'walkthrough_step_5_line_1': 'תמיכה',
  'walkthrough_step_5_line_2': '55555',
  'walkthrough_step_5_line_3': '55555',
  'walkthrough_step_5_line_4': '55555',
  'walkthrough_step_5_line_5': 'aaaaaa',

};

